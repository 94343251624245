import { css } from "@emotion/react"
import { Spinner } from "@shiftx/components"
import { Plugin } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import React from "react"
import ReactDOM from "react-dom"

// based on the example at: https://prosemirror.net/examples/upload/
export const uploadPlaceholder = new Plugin({
    state: {
        init() {
            return DecorationSet.empty
        },
        apply(tr, set) {
            // Adjust decoration positions to changes made by the transaction
            set = set.map(tr.mapping, tr.doc)

            // See if the transaction adds or removes any placeholders
            const action = tr.getMeta(this)

            if (action && action.add) {
                const element = document.createElement("div")
                element.className = "image-placeholder"

                ReactDOM.render(
                    <div
                        css={css`
                            position: relative;
                            display: inline-block;
                        `}
                    >
                        <div
                            css={css`
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                z-index: 1;
                            `}
                        >
                            <Spinner color="#000" />
                        </div>
                        <img
                            css={css`
                                opacity: 0.5;
                            `}
                            src={URL.createObjectURL(action.add.file)}
                        />
                    </div>,
                    element
                )

                const deco = Decoration.widget(action.add.pos, element, {
                    id: action.add.id,
                })
                set = set.add(tr.doc, [deco])
            } else if (action && action.remove) {
                set = set.remove(
                    set.find(null, null, spec => spec.id === action.remove.id)
                )
            }
            return set
        },
    },
    props: {
        decorations(state) {
            return this.getState(state)
        },
    },
})

import { css, Global } from "@emotion/react"
import {
    getColor,
    getThemeProp,
    interfaceColors,
    TextStyle,
} from "@shiftx/styles"
import React from "react"

export const GlobalStyle = (
    <Global
        styles={theme => css`
            .some-class {
                color: hotpink !important;
            }

            body {
                margin: 0px;
                background-color: var(--bg-primary);
                font-family: Inter, sans-serif;

                ${getThemeProp(theme, TextStyle.TextSmall)};
                -webkit-tap-highlight-color: transparent;
            }

            *:not(input):not(textarea) {
                user-select: none;
            }

            .ProseMirror * {
                user-select: auto;
            }

            a {
                color: inherit;
                text-decoration: none;
            }
            svg {
                display: block;
            }

            h1,
            h2 {
                margin: 0;
            }

            div {
                box-sizing: border-box;
            }

            div:focus {
                outline: none;
            }

            input,
            textarea {
                font-family: Inter, sans-serif;
            }

            body {
                overscroll-behavior-y: none;
            }

            :root {
                --brand-base: ${getColor(theme, "brandBase")};
                --brand-low: ${getColor(theme, "brandLow")};
                --brand-high: ${getColor(theme, "brandHigh")};
                --on-brand: ${getColor(theme, "onBrand")};
                --bg-primary: ${getColor(theme, "bgPrimary")};
                --bg-secondary: ${getColor(theme, "bgSecondary")};
                --bg-elevated: ${getColor(theme, "bgElevated")};
                --fg-100: ${getColor(theme, "fg100")};
                --fg-200: ${getColor(theme, "fg200")};
                --fg-300: ${getColor(theme, "fg300")};
                --fg-400: ${getColor(theme, "fg400")};
                --fg-400-solid: ${getColor(theme, "fg400solid")};
                --fg-low: ${getColor(theme, "fgLow")};
                --fg-medium: ${getColor(theme, "fgMedium")};
                --fg-high: ${getColor(theme, "fgHigh")};
                --fg-color: ${getColor(theme, "fgColor")};
                --interface-red: ${interfaceColors.red.default};
                --interface-red-darkest: ${interfaceColors.red.darkest};
                --interface-red-dark: ${interfaceColors.red.darker};
                --interface-red-lightest: ${interfaceColors.red.lightest};
                --interface-green: ${interfaceColors.green.default};
                --interface-green-lightest: ${interfaceColors.green.lightest};
                --interface-green-lighter: ${interfaceColors.green.lighter};
                --interface-yellow: ${interfaceColors.yellow.default};
                --interface-blue: ${interfaceColors.blue.default};
                --interface-blue-lighter: ${interfaceColors.blue.lighter};
                --interface-blue-lightest: ${interfaceColors.blue.lightest};
                --interface-blue-dark: ${interfaceColors.blue.darker};
                --interface-green-dark: ${interfaceColors.green.darker};
                --interface-green-darkest: ${interfaceColors.green.darkest};
            }
        `}
    />
)

import { isRunningClient } from "./isRunningClient"

const getFromStorage = (key, session = false) => {
    const storage = session ? sessionStorage : localStorage
    return JSON.parse(storage.getItem(key))
}

const getCurrentSession = () => {
    const currentSession = getFromStorage("sx:currentSession", true)
    if (currentSession) {
        return getFromStorage(
            `sx:session:membershipId=${currentSession.membershipId}`
        )
    }

    const lastActivatedSession = getFromStorage("sx:lastActivatedSession")
    if (lastActivatedSession) {
        return getFromStorage(
            `sx:session:membershipId=${lastActivatedSession.membershipId}`
        )
    }
    return null
}

export const useIsValidSession = () => {
    const isClient = isRunningClient()
    if (isClient) {
        const session = getCurrentSession()

        if (session) {
            const { expiresAt } = session
            if (expiresAt) {
                return Date.parse(expiresAt) > Date.now()
            }
        }
        return false
    }
}

export const useGetSession = () => {
    const isClient = isRunningClient()
    const isValid = useIsValidSession()

    if (isClient && isValid) {
        return getCurrentSession()
    }

    return null
}

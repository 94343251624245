import sanityClient from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"
import { createImageUrlBuilder } from "next-sanity"
import config from "./config"

const client = sanityClient(config)
export const imageBuilder = imageUrlBuilder(client)
export const urlFor = source => createImageUrlBuilder(config).image(source)

// Set up a preview client with serverless authentication for drafts
export const previewClient = sanityClient({
    ...config,
    useCdn: false,
    withCredentials: true,
    // token: process.env.SANITY_API_TOKEN,
})

export const sanityConfig = sanityClient({ ...config, useCdn: true })

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview = false) => {
    return usePreview ? previewClient : client
}

export default client

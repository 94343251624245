import { useNextSanityImage } from "next-sanity-image"
import { sanityConfig } from "../../config/sanity"
import Img from "next/image"

const myCustomImageBuilder = (width, height) => (imageUrlBuilder, options) => {
    /* return imageUrlBuilder.width(
        options.width || Math.min(options.originalImageDimensions.width, 1920)
    )
 */
    if (width && height) {
        return imageUrlBuilder
            .width(width)
            .height(height)
            .fit("clip")
            .auto("format")
            .dpr(2)
    }
    return imageUrlBuilder.fit("clip").auto("format").dpr(2)
}

export const Image = ({ image, width, height, ...props }) => {
    const imageProps = useNextSanityImage(sanityConfig, image, {
        imageBuilder: myCustomImageBuilder(width, height),
    })
    return (
        <Img
            {...imageProps}
            {...props}
            sizes="(max-width: 800px) 100vw, 800px"
        />
    )
}

import styled from "@emotion/styled"
import { Direction, X } from "@shiftx/icons"
import React, { Fragment, useState } from "react"
import { Button, Spinner } from "../.."
import { MenuItemType } from "../MenuList/MenuListItem"
import { DropdownMenu, DropdownProps, INamespaceKeys } from "./DropdownMenu"

const ButtonLabel = styled.div<{ nullable?: boolean }>`
    margin-right: ${props => (!props.nullable ? 12 : 0)}px;
`
const DirectionWrapper = styled.div<{ isNullable?: boolean }>`
    ${props =>
        props.isNullable ? "border-left: 1px solid var(--fg-400);" : ""}
    padding-left: 8px;
    height: 16px;
    align-items: center;
    display: flex;
`
const Xwrapper = styled.div`
    margin-right: 8px;
    padding-left: 8px;
    color: var(--fg-medium);
    height: 100%;
    align-items: center;
    display: flex;

    :hover {
        color: var(--fg-high);
    }
`

interface Props<Type, Item> extends DropdownProps<Type, Item> {
    initialId?: string
    nullable?: boolean
    isLoading?: boolean
    onRemoveItem?: () => void
}

export const DropDownSelect = <
    Type extends MenuItemType,
    Item extends INamespaceKeys<Type>
>({
    initialId = null,
    nullable = false,
    isLoading = false,
    onRemoveItem = null,
    ...props
}: Props<Type, Item>) => {
    const [selected, setSelected] = useState(
        props.items?.find(item => item.id === initialId)
    )

    return (
        <DropdownMenu
            initialId={initialId}
            {...props}
            onSelectItem={({ item, event }) => {
                if (!item?.sticky) {
                    setSelected(item)
                }
                props?.onSelectItem?.({ item, event })
            }}
        >
            <Button>
                {isLoading && <Spinner size={16} />}
                {!isLoading && (
                    <Fragment>
                        <ButtonLabel nullable={selected && nullable}>
                            {props?.children?.(selected)}
                        </ButtonLabel>
                        {selected && nullable && (
                            <Xwrapper
                                onClick={event => {
                                    event.stopPropagation()
                                    onRemoveItem && onRemoveItem()
                                    setSelected(null)
                                }}
                            >
                                <X width={10} height={10} />
                            </Xwrapper>
                        )}

                        <DirectionWrapper isNullable={selected && nullable}>
                            <Direction width={8} height={8} />
                        </DirectionWrapper>
                    </Fragment>
                )}
            </Button>
        </DropdownMenu>
    )
}

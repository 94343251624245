import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import Fuse from "fuse.js"
import React, { useRef, useState } from "react"
import { MenuList } from "../MenuList/MenuList"
import { DefaultListItem } from "../MenuList/MenuListItem"
import { SearchListInput } from "../SearchListInput/SearchListInput"

export interface StickyProps {
    searchValue: string
}

const NoItems = styled.div`
    padding: 18px 12px;
    text-align: center;
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    color: var(--fg-low);
`

const SearchListContainer = styled.div<{ showShadow?: boolean }>`
    background: var(--bg-elevated);

    border-radius: 8px;
    position: relative;
    z-index: 1;

    ${props =>
        props.showShadow && `box-shadow: 0px 4px 40px rgba(24, 25, 32, 0.1);`}
`

export interface OnSelectItem<Item> {
    item: Item
    searchValue: string
    event: React.MouseEvent
}

interface Props<Item> {
    items: Array<DefaultListItem<Item>>
    searchComponent?: (event: any) => React.ReactElement
    itemComp: ({
        item,
        searchValue,
        isHighlighted,
        onHover,
        scrollListRef,
    }: {
        item: DefaultListItem<Item>
        searchValue: string
        isHighlighted: boolean
        onHover: () => void
        scrollListRef: any
    }) => React.ReactElement
    searchKey?: string
    scrollheight?: string
    showEmptyPlaceholder?: boolean
    emptyPlaceholder?: React.ReactNode
    onSelectItem: ({
        item,
        searchValue,
        event,
    }: OnSelectItem<DefaultListItem<Item>>) => void
    externalSearchValue?: string
    showSearch?: boolean
    emptyBeforeSearch?: boolean
    showShadow?: boolean
    initialSelectedItemId?: string
}

export const SearchList = <Item extends any>({
    items = [],
    searchComponent = props => <SearchListInput {...props} />,
    searchKey = "name",
    scrollheight = "200",
    showEmptyPlaceholder = true,
    emptyPlaceholder,
    onSelectItem,
    itemComp,
    externalSearchValue,
    showSearch = true,
    initialSelectedItemId = null,
    emptyBeforeSearch = false,
    showShadow = false,
    ...props
}: Props<Item>) => {
    const [searchValue, setSearchValue] = useState("")
    const listRef = useRef(null)

    const regularItems = items.filter(item => !item?.sticky)
    const stickyItems = items.filter(item => item?.sticky)

    const fuse = new Fuse(regularItems, {
        shouldSort: true,
        threshold: 0.1,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [searchKey],
    })

    const searchPhrase = searchValue || externalSearchValue

    let searchedItems = searchPhrase
        ? fuse.search(searchPhrase).reduce((accumulator, currentValue) => {
              return [...accumulator, currentValue.item]
          }, [])
        : emptyBeforeSearch
        ? []
        : regularItems

    let filteredItems = [...searchedItems, ...stickyItems].filter(item => {
        if (
            typeof item?.disabled === "function" &&
            item.disabled({ searchValue })
        ) {
            return false
        }
        return true
    })

    const onChange = ({ target }) => {
        if (!externalSearchValue) {
            setSearchValue(target.value)
        }
    }

    const emptyState = emptyPlaceholder ? (
        emptyPlaceholder
    ) : (
        <NoItems>No items found</NoItems>
    )

    return (
        <SearchListContainer
            id="searchlist"
            ref={listRef}
            tabIndex={0}
            showShadow={showShadow}
            {...props}
        >
            {showSearch &&
                searchComponent &&
                searchComponent({
                    value: searchValue,
                    onChange,
                })}

            {showEmptyPlaceholder && filteredItems.length <= 0 && emptyState}

            {filteredItems.length > 0 && (
                <MenuList
                    showShadow={false}
                    items={filteredItems.map(item => {
                        return {
                            ...item,
                            disabled: () => {
                                return item?.disabled?.({ searchValue })
                            },
                        }
                    })}
                    itemComp={({
                        item,
                        onHover,
                        isHighlighted,
                        scrollListRef,
                    }) => {
                        return itemComp({
                            item,
                            searchValue,
                            onHover,
                            isHighlighted,
                            scrollListRef,
                        })
                    }}
                    useItemAction={false}
                    onSelectItem={async ({ item, event }) => {
                        item?.onSelectItem &&
                            (await item?.onSelectItem?.({
                                item,
                                searchValue,
                                event,
                            }))

                        await onSelectItem({
                            item,
                            searchValue,
                            event,
                        })

                        setSearchValue("")
                    }}
                    initialSelectedItemId={initialSelectedItemId}
                />
            )}
        </SearchListContainer>
    )
}

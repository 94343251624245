import { graphqlClient } from "./graphqlClient"

export const NewsletterQuery = /* GraphQL */ `
    mutation NewsletterSingupMutation($input: NewsletterSignupInput) {
        newsletterSignup(input: $input) {
            ok
        }
    }
`

export const BookDemoQuery = /* GraphQL */ `
    mutation BookDemoMutation($input: DemoRequestInput) {
        demoRequest(input: $input) {
            ok
        }
    }
`

export const ContactSalesQuery = /* GraphQL */ `
    mutation ContactSalesMutation($input: ContactSalesInput) {
        contactSales(input: $input) {
            ok
        }
    }
`

export const newsletterSubscribe = (email, singlePackage = false) => {
    return graphqlClient({
        query: NewsletterQuery,
        variables: { input: { email, singlePackage } },
        useEdge: true,
        usePublicKey: true,
    })
}

export const bookDemo = ({ name, email, title, company, size }) => {
    return graphqlClient({
        query: BookDemoQuery,
        variables: { input: { name, email, title, company, size } },
        useEdge: true,
        usePublicKey: true,
    })
}

export const contactSales = ({ name, email, company, size, note }) => {
    return graphqlClient({
        query: ContactSalesQuery,
        variables: { input: { name, email, company, size, note } },
        useEdge: true,
        usePublicKey: true,
    })
}

import styled from "@emotion/styled"
import { Dots } from "@shiftx/icons"
import React from "react"
import { MenuItemType } from "../MenuList/MenuListItem"
import { DropdownMenu, DropdownProps, INamespaceKeys } from "./DropdownMenu"

interface DropDownButton<Type, Item> extends DropdownProps<Type, Item> {
    insideButton?: boolean
}

const DotContainer = styled.div`
    cursor: pointer;
    color: var(--fg-medium);
    :hover {
        color: var(--fg-high);
    }
    padding: 4px;
`
export const DropDownButton = <
    Type extends MenuItemType,
    Item extends INamespaceKeys<Type>
>({
    insideButton = true,
    ...props
}: DropDownButton<Type, Item>) => {
    if (insideButton) {
        return <DropdownMenu useSearch={false} {...props} />
    } else {
        return (
            <DropdownMenu useSearch={false} {...props}>
                <DotContainer>
                    <Dots width={16} height={16} />
                </DotContainer>
            </DropdownMenu>
        )
    }
}

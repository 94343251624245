import styled from "@emotion/styled"
import React, { HTMLAttributes } from "react"
import { useCardContext } from "./Card"

interface Props extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode
}
const BodyContainer = styled.div`
    padding: 24px;
`

export function CardBody({ children, ...rest }: Props) {
    useCardContext("CardBody")
    return <BodyContainer {...rest}>{children}</BodyContainer>
}

import styled from "@emotion/styled"
import { ChevronFilledRight } from "@shiftx/icons"
import React, { useEffect, useState } from "react"
import { SearchListItemContainer, usePopover } from "../.."
import { MenuList } from "../MenuList/MenuList"
import { MenuListItem } from "../MenuList/MenuListItem"
import { PopoverContent } from "../Popover/PopoverContent"

const ContextMenuChevron = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-left: 24px;
`

export const useContextMenu = (reference, open, close) => {
    const [showContextMenu, setContextMenu] = useState(null)

    useEffect(() => {
        if (reference) {
            const contextMenu = event => {
                open(event)
                event.preventDefault()
                setContextMenu({ x: event.pageX, y: event.pageY })
            }

            if (!showContextMenu) {
                if (reference) {
                    reference.addEventListener("contextmenu", contextMenu)
                }
            }

            return () => {
                if (reference) {
                    reference.removeEventListener("contextmenu", contextMenu)
                }
            }
        }
    }, [reference])

    return showContextMenu
}

export const ContextMenu = ({
    items = [],
    onSelect,
    reference,
}: {
    items: any[]
    onSelect?: ({ item }: { item: any }) => void
    reference?: any
}) => {
    const popover = usePopover()
    const context = useContextMenu(
        reference,
        () => {
            popover.setShow("contextMenu")
        },
        () => {
            popover.hide()
        }
    )

    if (!context) {
        return null
    }

    const virtualReference = {
        getBoundingClientRect: () => {
            return {
                top: context.y,
                left: context.x,
                bottom: context.y,
                right: context.x,
                width: 0,
                height: 0,
                x: 0,
                y: 0,
                toJSON: null,
            }
        },
    }

    return (
        <PopoverContent
            id="contextMenu"
            block={true}
            {...popover}
            placement="bottom-start"
            reference={virtualReference}
        >
            <MenuList
                showShadow={true}
                items={items}
                onSelectItem={({ item, event }) => {
                    onSelect && onSelect({ item })
                    popover.hide()
                }}
                enableScroll={false}
                itemComp={props => {
                    return (
                        <SearchListItemContainer {...props} showPadding={true}>
                            <MenuListItem type={"text"} {...props} />
                            {props.item.subMenu && (
                                <ContextMenuChevron>
                                    <ChevronFilledRight
                                        width={12}
                                        height={12}
                                    />
                                </ContextMenuChevron>
                            )}
                        </SearchListItemContainer>
                    )
                }}
            />
        </PopoverContent>
    )
}

import styled from "@emotion/styled"
import { flip, offset, shift, useFloating } from "@floating-ui/react-dom"
import { Placement } from "@popperjs/core"
import { Popover, Portal } from "@shiftx/components"
import { AnimatePresence, motion } from "framer-motion"
import React, { Fragment, useEffect, useLayoutEffect } from "react"

interface Props extends Popover {
    title?: string
    children: React.ReactNode
    id: string
    block?: boolean
    placement?: Placement
    transformAnimation?: boolean
    reference?: any
    floatingRef?: any
    style?: any
}

const Blocker = styled.div`
    z-index: 1000000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: grid;
    place-items: center;
    padding: 0 160px;
`

const PopoverContainer = styled.div`
    z-index: 10000000;
`

const PopoverChild = ({
    children,
    setShow,
    block = false,
    placement = "bottom-start",
    transformAnimation = false,
    floatingRef,
    style,
}: Props) => {
    const isVerticalAnimation =
        placement.includes("bottom") || placement.includes("top")

    const variants = transformAnimation
        ? {
              horizontalInitial: { opacity: 0, x: 10 },
              horizontal: { opacity: 1, x: 1 },
              verticalInitial: { opacity: 0, y: 10 },
              vertical: { opacity: 1, y: 1 },
          }
        : {
              horizontalInitial: { opacity: 0, x: 1 },
              horizontal: { opacity: 1, x: 1 },
              verticalInitial: { opacity: 0, y: 1 },
              vertical: { opacity: 1, y: 1 },
          }

    return (
        <Fragment>
            {block && (
                <Blocker
                    tabIndex={0}
                    onClick={e => {
                        e.stopPropagation()
                        setShow(null)
                    }}
                    onContextMenu={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        setShow(null)
                    }}
                />
            )}
            <PopoverContainer
                style={style}
                ref={floatingRef}
                onClick={e => {
                    e.stopPropagation()
                }}
                onMouseDown={e => {
                    e.stopPropagation()
                }}
            >
                <motion.div
                    key="popover"
                    variants={variants}
                    initial={
                        isVerticalAnimation
                            ? "verticalInitial"
                            : "horizontalInitial"
                    }
                    animate={isVerticalAnimation ? "vertical" : "horizontal"}
                    exit={
                        isVerticalAnimation
                            ? "verticalInitial"
                            : "horizontalInitial"
                    }
                    transition={{
                        opacity: { duration: 0.1, ease: "easeOut" },
                    }}
                >
                    {children}
                </motion.div>
            </PopoverContainer>
        </Fragment>
    )
}

interface PopoverProps extends Props {
    offset?: [number, number]
    strategy?: "absolute" | "fixed"
}

export const PopoverContent = ({
    offset: offsetValues = [0, 24],
    strategy: initialStrategy,
    ...props
}: PopoverProps) => {
    const { x, y, refs, reference, floating, strategy, update } = useFloating({
        placement: props.placement,
        middleware: [
            shift(),
            flip(),
            offset({ mainAxis: offsetValues[1], crossAxis: offsetValues[0] }),
        ],
        strategy: initialStrategy,
    })

    const ref = props.reference ? props.reference : props.getReference(props.id)

    useLayoutEffect(() => {
        reference(ref)
    }, [ref])

    useLayoutEffect(update, [update, props.show === props.id])

    let resizeObserver = null

    if ("ResizeObserver" in window) {
        resizeObserver = new ResizeObserver(entries => {
            update()
        })
    }

    useEffect(() => {
        if (refs.floating.current && resizeObserver) {
            resizeObserver.observe(refs.floating.current)
        }

        return () => {
            if (refs.floating.current && resizeObserver) {
                resizeObserver.unobserve(refs.floating.current)
            }
        }
    }, [refs.floating.current])

    return (
        <Portal>
            <AnimatePresence>
                {props.show === props.id && (
                    <PopoverChild
                        floatingRef={floating}
                        style={{
                            position: strategy,
                            top: y ?? "",
                            left: x ?? "",
                        }}
                        {...props}
                    />
                )}
            </AnimatePresence>
        </Portal>
    )
}

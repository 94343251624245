import styled from "@emotion/styled"
import { grayscaleLight } from "@shiftx/styles"

export const CardMetaRow = styled.div`
    display: flex;
    color: ${grayscaleLight[100]};
    margin-bottom: 16px;
    align-items: center;

    & > div {
        width: 50%;
    }

    &:last-child {
        margin-bottom: 0;
    }
`

export const CardMetaRowLabel = styled.div`
    display: flex;
    color: var(--fg-medium);
`

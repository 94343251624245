import styled from "@emotion/styled"
import { X } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
import { useCardContext } from "./Card"

interface Props {
    title?: string
    children?: React.ReactNode
    close?: (event: React.MouseEvent | React.TouchEvent) => void
}

const HeaderContainer = styled.div`
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--fg-200);
    ${props => {
        return getThemeProp(props.theme, TextStyle.TextSmall)
    }};
`

const Close = styled.div`
    cursor: pointer;
`

export function CardHeader({ title = "", children, close, ...props }: Props) {
    useCardContext("CardHeader")
    return (
        <HeaderContainer {...props}>
            {title && <div>{title}</div>}
            {children}
            {!!close && (
                <Close onMouseDown={close} onTouchStart={close}>
                    <X width={16} height={16} />
                </Close>
            )}
        </HeaderContainer>
    )
}

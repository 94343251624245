import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
    Bold as BoldIcon,
    Bullets,
    Goto,
    Image as ImageIcon,
    Italic as ItalicIcon,
    Link as LinkIcon,
    OrderedList as OrderedListIcon,
    Trash,
} from "@shiftx/icons"
import { Node, nodeInputRule, NodeViewProps } from "@tiptap/core"
import Bold from "@tiptap/extension-bold"
import BulletList from "@tiptap/extension-bullet-list"
import Document from "@tiptap/extension-document"
import Dropcursor from "@tiptap/extension-dropcursor"
import GapHistory from "@tiptap/extension-gapcursor"
import History from "@tiptap/extension-history"
import Image from "@tiptap/extension-image"
import Italic from "@tiptap/extension-italic"
import Link from "@tiptap/extension-link"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Paragraph from "@tiptap/extension-paragraph"
import Placeholder from "@tiptap/extension-placeholder"
import Text from "@tiptap/extension-text"
import {
    BubbleMenu,
    Editor,
    EditorContent,
    Extension,
    NodeViewWrapper,
    ReactNodeViewRenderer,
    useEditor,
} from "@tiptap/react"
import React, { useEffect, useRef, useState } from "react"
import { Button, Input } from "../.."
import { Hint } from "../Hint/Hint"
import insertFiles, { UploadOptions } from "./insertFiles"
import { uploadPlaceholder } from "./uploadPlaceHolder"
import { uploadPlugin } from "./UploadPlugin"

const Container = styled.div`
    border-radius: 4px;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .ProseMirror-selectednode {
        border-radius: 2px;
        box-shadow: var(--brand-base) 0px 0px 0px 2px;
    }

    .ProseMirror {
        padding: 16px;
        white-space: pre-wrap;
        flex: 1;

        a {
            color: blue;
            cursor: pointer;
        }

        img {
            max-width: 100%;
            box-sizing: border-box;
        }

        a {
            cursor: pointer;
            border-bottom: 1px solid var(--fg-high);

            :hover {
                border-color: var(--brand-base);
                color: var(--brand-base);
            }
        }
    }

    .ProseMirror p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
`

const Toolbar = styled.div`
    border-bottom: 1px solid var(--fg-200);
    padding: 8px;
    position: sticky;
    top: 0;
    display: flex;
`

const ButtonContainer = styled.div`
    margin-left: 4px;
`

const EnabledButton = styled.div<{ isActive?: boolean }>`
    cursor: pointer;
    border-radius: 2px;
    padding: 4px;

    ${props =>
        !props.isActive &&
        `
            :hover {
                background-color: var(--fg-200);
            }
        `}

    ${props =>
        props.isActive &&
        `
            color: var(--brand-base);
            background-color: var(--brand-low);
        `};
`

const DisabledButton = styled.div`
    padding: 4px;
`

const ButtonWrapper = ({
    children,
    disabled = false,
    isActive = false,
    ...props
}) => {
    if (disabled) {
        return <DisabledButton>{children}</DisabledButton>
    }

    return (
        <EnabledButton isActive={isActive} {...props}>
            {children}
        </EnabledButton>
    )
}

interface Props {
    initialContent?: string
    autofocus?: boolean
    onchange?: ({ content }: { content: string }) => void
    images?: any[]
    uploadOptions?: UploadOptions
}

export const getImageUrl = path => {
    return path + (path.match(/\?/) ? "&" : "?") + "fit=max&w=1000&h=1000&dpr=2"
}

const ImageComponent = (props: NodeViewProps) => {
    props
    const images = props.editor?.storage?.["sx-file"]?.images ?? []
    const ref = React.createRef<HTMLImageElement>()

    const imageId = props.node.attrs.id
    const file = images?.find(image => image.id === imageId)

    const isActive = props.selected

    if (!file) {
        return null
    }

    return (
        <NodeViewWrapper className="react-component" contentEditable={false}>
            <div
                className="loading"
                data-drag-handle=""
                css={css`
                    background-color: var(--fg-100);
                    &.loading {
                        width: 100%;
                        height: 100px;
                    }

                    &.loading:after {
                        content: "loading image...";
                        color: var(--fg-400);
                        width: 100%;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                `}
            >
                <img
                    ref={ref}
                    onLoad={() => {
                        const parentNode = ref.current.parentNode as HTMLElement

                        parentNode.classList.remove("loading")
                    }}
                    src={getImageUrl(file.path)}
                    css={css`
                        display: block;
                        position: relative;
                        width: 100%;

                        ${isActive &&
                        css`
                            box-shadow: 0 0 0 2px var(--brand-base);
                        `}
                    `}
                />
            </div>
        </NodeViewWrapper>
    )
}

const SXFile = (images = []) => {
    const inputRegex = /(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/

    return Node.create({
        name: "sx-file",
        group: "inline",
        content: "text*",
        inline: true,
        atom: true,
        attrs: {
            id: { default: null },
            src: { default: null },
        },
        draggable: false,
        selectable: true,

        renderHTML: ({ HTMLAttributes }) => {
            return ["sx-file", HTMLAttributes]
        },

        addCommands() {
            return {
                setImage:
                    options =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: options,
                        })
                    },
            }
        },

        addInputRules() {
            return [
                nodeInputRule({
                    find: inputRegex,
                    type: this.type,
                    getAttributes: match => {
                        const [, , alt, src, title] = match

                        return { src, alt, title }
                    },
                }),
            ]
        },

        parseHTML: () => {
            return [
                {
                    tag: "sx-file",
                    getAttrs: node => {
                        return {
                            id: node.getAttribute("id"),
                            src: node.getAttribute("src"),
                        }
                    },
                },
            ]
        },

        addStorage: () => {
            return { images }
        },

        addNodeView: () => {
            return ReactNodeViewRenderer(ImageComponent)
        },

        addAttributes: () => {
            return {
                id: {
                    parseHTML: element => {
                        return element.getAttribute("id")
                    },
                },
                src: {
                    parseHTML: element => {
                        return element.getAttribute("src")
                    },
                },
            }
        },
    })
}

export const useRichTextEditor = ({
    initialContent = "",
    autofocus = false,
    onchange,
    images = [],
    uploadOptions = null,
}: Props) => {
    const conditionalPlugins = []

    if (uploadOptions) {
        const ProseMirrorPlugins = Extension.create({
            addProseMirrorPlugins() {
                return [uploadPlugin(uploadOptions), uploadPlaceholder]
            },
        })

        conditionalPlugins.push(ProseMirrorPlugins)
    }

    const editor = useEditor({
        onUpdate: ({ editor }) => {
            const content = editor.getHTML()
            onchange?.({ content })
        },
        onFocus: () => {},
        onBlur: () => {},
        autofocus: autofocus ? "end" : false,
        extensions: [
            Document,
            Paragraph,
            Text,
            SXFile(images),
            Bold,
            Italic,
            Link.configure({
                openOnClick: false,
            }),
            ListItem,
            BulletList,
            OrderedList,
            Image,
            ...conditionalPlugins,
            History,
            GapHistory,
            Dropcursor,
            Placeholder.configure({
                placeholder: "Start typing...",
            }),
        ],
        content: initialContent,
    })

    useEffect(() => {
        if (editor?.storage?.["sx-file"]) {
            editor.storage["sx-file"].images = images
        }
    }, [images])

    return editor
}

export const getHTML = ({ editor }) => {
    return editor.getHTML()
}

interface EditorProps {
    editor: Editor
    showToolbar?: boolean
    uploadOptions?: UploadOptions
}

const ImageButton = ({
    editor,
    uploadOptions,
}: {
    editor: Editor
    uploadOptions: UploadOptions
}) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const isActive = false

    return (
        <ButtonWrapper
            isActive={isActive}
            onClick={() => {
                inputRef.current.click()
            }}
        >
            <ImageIcon width="16" height="16" />

            <input
                type="file"
                css={css`
                    display: none;
                `}
                accept="image/*"
                onChange={async event => {
                    const pos = editor.view.state.selection.to

                    insertFiles({
                        view: editor.view,
                        event,
                        pos,
                        files: [...event.target.files],
                        uploadOptions,
                    })
                }}
                ref={inputRef}
            />
        </ButtonWrapper>
    )
}

export const RichTextEditor = ({
    editor,
    showToolbar = true,
    uploadOptions = null,
}: EditorProps) => {
    if (!editor) {
        return null
    }

    const editorWidth = `640px`

    return (
        <Container>
            {showToolbar && (
                <Toolbar
                    css={css`
                        padding: 10px
                            clamp(
                                0px,
                                calc((100% - ${editorWidth} + 16px) / 2),
                                calc((100% - ${editorWidth} + 16px) / 2)
                            );
                    `}
                >
                    <Hint helpText="Bold" inline={false}>
                        <ButtonWrapper
                            isActive={editor.isActive("bold")}
                            onClick={() => {
                                editor.chain().focus().toggleBold().run()
                            }}
                        >
                            <BoldIcon width="16" height="16" />
                        </ButtonWrapper>
                    </Hint>

                    <ButtonContainer>
                        <Hint helpText="Italic" inline={false}>
                            <ButtonWrapper
                                isActive={editor.isActive("italic")}
                                onClick={() => {
                                    editor.chain().focus().toggleItalic().run()
                                }}
                            >
                                <ItalicIcon width="16" height="16" />
                            </ButtonWrapper>
                        </Hint>
                    </ButtonContainer>

                    <ButtonContainer>
                        <Hint helpText="Link" inline={false}>
                            <ButtonWrapper
                                isActive={editor.isActive("link")}
                                onClick={() => {
                                    editor.chain().focus().toggleLink().run()
                                }}
                            >
                                <LinkIcon width="16" height="16" />
                            </ButtonWrapper>
                        </Hint>
                    </ButtonContainer>

                    <ButtonContainer>
                        <Hint helpText="Bullet list" inline={false}>
                            <ButtonWrapper
                                isActive={editor.isActive("bulletList")}
                                onClick={() => {
                                    editor
                                        .chain()
                                        .focus()
                                        .toggleBulletList()
                                        .run()
                                }}
                            >
                                <Bullets width="16" height="16" />
                            </ButtonWrapper>
                        </Hint>
                    </ButtonContainer>

                    <ButtonContainer>
                        <Hint helpText="Ordered list" inline={false}>
                            <ButtonWrapper
                                isActive={editor.isActive("orderedList")}
                                onClick={() => {
                                    editor
                                        .chain()
                                        .focus()
                                        .toggleOrderedList()
                                        .run()
                                }}
                            >
                                <OrderedListIcon width="16" height="16" />
                            </ButtonWrapper>
                        </Hint>
                    </ButtonContainer>

                    {uploadOptions && (
                        <ButtonContainer>
                            <Hint helpText="Add image" inline={false}>
                                <ImageButton
                                    uploadOptions={uploadOptions}
                                    editor={editor}
                                />
                            </Hint>
                        </ButtonContainer>
                    )}
                </Toolbar>
            )}

            <EditorContent
                editor={editor}
                css={css`
                    display: flex;
                    flex: 1;
                    font-size: 14px;
                    padding: 0
                        clamp(
                            0px,
                            calc((100% - ${editorWidth}) / 2),
                            calc((100% - ${editorWidth}) / 2)
                        );
                    overflow-y: auto;
                `}
            />

            <LinkMenu editor={editor} />
        </Container>
    )
}

const LinkMenu = ({ editor }: { editor: Editor }) => {
    const defaultLink = editor.getAttributes("link").href ?? ""
    const [link, setLink] = useState(defaultLink)

    useEffect(() => {
        console.log("set defaultlink")
        setLink(defaultLink)
    }, [defaultLink])

    const showMenu = useRef(true)

    useEffect(() => {
        showMenu.current = true
    }, [editor.state.selection.to])

    const saveLink = () => {
        editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .updateAttributes("link", {
                href: link,
            })
            .run()

        editor.commands.setTextSelection(editor.state.selection.to + 1)
    }
    return (
        <BubbleMenu
            editor={editor}
            tippyOptions={{ duration: 100, offset: [100, -64] }}
            shouldShow={({ editor }) => {
                return editor.isActive("link") && showMenu.current
            }}
        >
            <div
                css={css`
                    display: grid;
                    background: #fff;
                    gap: 8px;
                    align-items: center;
                    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    grid-template-columns: auto auto auto;
                `}
            >
                <Input
                    key={defaultLink}
                    placeholder="https://"
                    value={link}
                    onChange={event => {
                        setLink(event.target.value)
                    }}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            saveLink()
                            event.preventDefault()
                            //Close
                        }
                    }}
                    css={css`
                        border-color: transparent;

                        &:hover,
                        &:focus {
                            border-color: transparent;
                        }
                    `}
                />

                <button
                    type="button"
                    onClick={saveLink}
                    css={css`
                        background: none;
                        border: none;
                        font-size: 12px;
                        cursor: pointer;
                    `}
                >
                    Save
                </button>

                <div
                    css={css`
                        display: flex;
                        gap: 8px;
                        border-left: 1px solid var(--fg-300);
                        height: 100%;
                        align-items: center;
                        padding: 0 8px;
                    `}
                >
                    <a
                        href={link}
                        target="_blank"
                        css={css`
                            color: var(--fg-high);
                            border-bottom: 0;
                        `}
                    >
                        <Goto width="16" height="16" />
                    </a>
                    <a
                        onClick={() => {
                            console.log("remove")
                            editor.chain().focus().unsetLink().run()
                        }}
                        css={css`
                            cursor: pointer;
                            margin-left: 4px;

                            :focus {
                                border: red;
                            }
                        `}
                        tabIndex={0}
                    >
                        <Trash width="16" height="16" />
                    </a>
                </div>
            </div>
        </BubbleMenu>
    )
}

import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Hint, Spinner } from "@shiftx/components"
import { Star } from "@shiftx/icons"
import { interfaceColors } from "@shiftx/styles"

interface Props {
    isFavorite: boolean
    isFetching: boolean
    onClick: (event: any) => void
}

const Flex = styled.div`
    display: flex;
`

const ClickArea = styled.div<{
    isFavorite: boolean
}>`
    color: ${props =>
        props.isFavorite ? interfaceColors.yellow.default : `var(--fg-low)`};

    opacity: ${props => (props.isFavorite ? 100 : 50)}%;
    position: relative;
    cursor: pointer;
    :hover {
        opacity: 100%;

        ${props =>
            props.isFavorite &&
            css`
                .star {
                    color: ${interfaceColors.yellow.darkest};
                }
            `}
    }
`

const Relative = styled.div`
    position: relative;
`

const After = styled.div`
    :after {
        content: "";
        position: absolute;
        left: -8px;
        top: -8px;
        width: 32px;
        height: 32px;
        background: transparent;
    }
`

const Favorite = ({ isFavorite, isFetching, onClick }: Props) => {
    if (isFetching) {
        return (
            <Flex>
                <Spinner size={16} />
            </Flex>
        )
    }

    return (
        <ClickArea onClick={onClick} isFavorite={isFavorite}>
            <Relative>
                <After>
                    <Star width="16" height="16" className="star" />
                </After>
            </Relative>
        </ClickArea>
    )
}

export const FavoriteIcon = (props: Props) => {
    return (
        <Hint helpText={props.isFavorite ? "Remove favorite" : "Add favorite"}>
            <Favorite {...props} />
        </Hint>
    )
}

import { Hint } from "@shiftx/components"
import { Buildings, Lock, People } from "@shiftx/icons"

export const AccessToolTip = ({
    isPublic = true,
    membersLength = 0,
    children,
}) => {
    let helpText = ""
    if (isPublic === false) {
        if (membersLength > 0) {
            helpText = `Shared with ${membersLength} users`
        } else {
            helpText = `Private only for me`
        }
    } else if (isPublic === true) {
        helpText = `Shared with workspace`
    }

    return <Hint helpText={helpText}>{children}</Hint>
}

const AccessIconChild = ({ isPublic, membersLength, ...props }) => {
    if (isPublic === false) {
        if (membersLength > 0) {
            return <People width={16} height={16} {...props} />
        } else {
            return <Lock width={16} height={16} {...props} />
        }
    } else if (isPublic === true) {
        return <Buildings width={16} height={16} {...props} />
    }

    return null
}

export const AccessIcon = ({
    showHint = false,
    isPublic,
    membersLength,
    ...props
}) => {
    return showHint ? (
        <AccessToolTip isPublic={isPublic} membersLength={membersLength}>
            <AccessIconChild
                isPublic={isPublic}
                membersLength={membersLength}
                {...props}
            />
        </AccessToolTip>
    ) : (
        <AccessIconChild
            isPublic={isPublic}
            membersLength={membersLength}
            {...props}
        />
    )
}

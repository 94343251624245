import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Hint } from "@shiftx/components"
import React from "react"
import { actorCategories } from "../../data/actorCategories"
import { actorIcons } from "../../data/actorIcons"
import { actorIconsShim } from "../../data/actorIconShim"

export const switchSvgUrl = icon => {
    const url =
        process.env.NODE_ENV === "development"
            ? `/src/actorIcons/distSvg/${icon}.svg#${icon}`
            : `/versions/${process.env.version}/app/static/actorIcons/${icon}.svg#${icon}`
    return url
}

export function removePrefixActorIcon(icon) {
    const prefix = ["fab-", "fas-"]
    const foundPrefix = prefix.find(pre => icon.startsWith(pre))
    const iconIdExcludingPrefix = foundPrefix
        ? icon.slice(foundPrefix.length)
        : icon
    return iconIdExcludingPrefix
}

export const LoadSvg = ({ icon, size = 40, factor = 0.5, ...rest }) => {
    return (
        <img
            src={switchSvgUrl(icon)}
            width={size * factor}
            height={size * factor}
            {...rest}
        />
    )
}

export const getFeatureFlag = flagKey => {
    const flags = JSON.parse(window.localStorage.getItem("flags"))
    return Boolean(flags?.[flagKey])
}
interface PropsActorAvatar {
    actor?: { icon?: string; category?: string }
    size?: number
    checked?: boolean
    onClick?: () => void
    multiple?: number | null
    hint?: boolean
}

export function ActorAvatar({
    size = 24,
    actor,
    multiple = null,
    hint = false,
    ...props
}: PropsActorAvatar) {
    const enableNewActorIcons = getFeatureFlag("enableNewActorIcons")

    return enableNewActorIcons ? (
        <ActorAvatarNEW
            size={size}
            actor={actor}
            multiple={multiple}
            hint={hint}
            {...props}
        />
    ) : (
        <ActorAvatarOLD
            size={size}
            actor={actor}
            multiple={multiple}
            hint={hint}
            {...props}
        />
    )
}

const Icon = styled.svg`
    width: 50%;
    height: 50%;
`

export function ActorAvatarOLD({
    size = 24,
    hint = false,
    actor,
    multiple = null,
    ...props
}: PropsActorAvatar) {
    const category = actorCategories.find(
        (ac: any) => ac.id === actor?.category
    )
    const icon = actorIcons?.find(ai => ai.id === actor?.icon) ?? "Account"

    if (!category && !multiple) {
        return null
    }

    const iconContainer = (
        <IconContainer
            multiple={multiple}
            size={size}
            color={category?.color}
            {...props}
        >
            {!!multiple && <MultipleActors>+{multiple}</MultipleActors>}
            {icon && !multiple && (
                <Icon
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="white"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d={icon?.path}
                    />
                </Icon>
            )}
        </IconContainer>
    )

    if (hint) {
        return <Hint helpText={category?.name}>{iconContainer}</Hint>
    } else {
        return iconContainer
    }
}

export function ActorAvatarNEW({
    size = 24,
    actor,
    multiple = null,
    hint = false,
    ...props
}: PropsActorAvatar) {
    const category = actorCategories.find(ac => ac.id === actor?.category)

    if (!category && !multiple) {
        return null
    }

    // Convert old sx icons to fontawesome
    if (actor?.icon && actorIconsShim?.[actor.icon]) {
        actor.icon = actorIconsShim?.[actor.icon]
    }

    const iconContainer = (
        <IconContainer
            multiple={multiple}
            size={size}
            color={category?.color}
            {...props}
        >
            {!!multiple && <MultipleActors>+{multiple}</MultipleActors>}
            {actor?.icon && (
                <LoadSvg
                    icon={actor?.icon}
                    size={size}
                    css={css`
                        filter: invert(1);
                    `}
                />
            )}
        </IconContainer>
    )

    if (hint) {
        return <Hint helpText={category?.name}>{iconContainer}</Hint>
    } else {
        return iconContainer
    }
}

interface PropsActorAvatarPicker {
    actor: { icon: string; category: string }
    size?: number
    checked: boolean
    onClick?: () => void
}

export const ActorAvatarPicker = ({
    size = 48,
    actor,
    checked,
    onClick,
    ...rest
}: PropsActorAvatarPicker) => {
    const category = actorCategories.find(ac => ac.id === actor?.category)

    // Convert old sx icons to fontawesome
    if (actor?.icon && actorIconsShim?.[actor.icon]) {
        actor.icon = actorIconsShim?.[actor.icon]
    }

    const iconLabel = removePrefixActorIcon(actor.icon)

    const Actor = (
        <div
            onClick={onClick}
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                width: ${size}px;
                height: ${size}px;
                border-radius: 50%;
                cursor: pointer;
                margin: 2px 2px;
                ${checked &&
                css`
                    background-color: ${category?.color};
                `}
            `}
            {...rest}
        >
            <LoadSvg
                icon={actor.icon}
                size={size}
                css={css`
                    ${checked ? `filter: invert(1)` : `filter: invert(0.4)`};
                `}
            />
        </div>
    )

    if (iconLabel) {
        return <Hint helpText={iconLabel}>{Actor}</Hint>
    } else {
        return Actor
    }
}

const IconContainer = styled.div<{
    multiple: number | null
    color: string
    size: number
    checked: boolean
    clickable: boolean
}>`
    display: flex;
    cursor: ${props => (props.clickable ? `pointer` : `default`)};
    background-color: ${props =>
        props.multiple ? `var(--fg-200)` : props.color};
    fill: var(--fg-medium);
    border-radius: 100%;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    align-items: center;
    justify-content: center;
    ${props =>
        props.checked &&
        css`
            fill: white;
            background-color: ${props.color};
        `}
`
const MultipleActors = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
`

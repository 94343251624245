import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React, { ButtonHTMLAttributes, HTMLAttributes } from "react"
import { Spinner } from "../Spinner/Spinner"

type ButtonTypeExtends = {
    disabled?: boolean
    showDot?: boolean
}

const BaseButton = styled("button")<ButtonTypeExtends>`
    ${props => {
        return getThemeProp(props.theme, TextStyle.TextSmall)
    }};
    appearance: none;
    text-decoration: none;
    text-align: center;
    background-color: transparent;
    border: none;
    color: var(--fg-high);
    position: relative;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    display: inline-flex;
    height: 36px;
    padding: 0 12px;
    -webkit-font-smoothing: antialiased;
    width: fit-content;
    margin: 0;

    ${props =>
        !props.disabled &&
        css`
            cursor: pointer;
        `};

    :active,
    :focus {
        outline: none;
    }

    transition: box-shadow 0.2s;
    white-space: nowrap;
    vertical-align: middle;

    ${props =>
        props.showDot &&
        css`
            :after {
                content: "";
                position: absolute;
                right: 8px;
                top: -7px;
                width: 10px;
                height: 10px;
                border: 2px solid #fff;
                border-radius: 50%;
                background-color: var(--brand-base);
            }
        `}
`

export enum ButtonType {
    Primary = "primary",
    Secondary = "secondary",
    Red = "red",
    Green = "green",
}

const PrimaryButton = styled(BaseButton)`
    background-color: var(--brand-base);
    color: #ffffff;

    ${props =>
        !props.disabled &&
        css`
            :hover {
                background-color: #3838d4;
            }

            :active,
            :focus {
                background-color: #3838d4;
                box-shadow: 0px 0px 0px 4px var(--fg-200);
            }
        `}

    ${props =>
        props.disabled &&
        css`
            color: var(--fg-400);
            background-color: var(--fg-200);
        `}
`
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: React.ReactNode | React.ReactNode[]
    variant?: ButtonType
    onClick?: (event?: React.MouseEvent) => void
    showDot?: boolean
    disabled?: boolean
}

const SecondaryButton = styled(BaseButton)<ButtonProps>`
    background-color: var(--bg-primary);
    border: 1px solid var(--fg-300);

    ${props =>
        !props.disabled &&
        css`
            :hover {
                border-color: var(--fg-400);
            }

            :active,
            :focus {
                border-color: var(--fg-400);
                box-shadow: 0px 0px 0px 4px var(--fg-200);
            }
        `}

    ${props =>
        props.disabled &&
        css`
            color: var(--fg-low);
        `}
`
const RedButton = styled(BaseButton)<ButtonProps>`
    background-color: var(--interface-red);
    color: #fff;

    :hover {
        background-color: var(--interface-red-dark);
    }

    :active,
    :focus {
        border-color: var(--fg-400);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }

    ${props =>
        props.disabled &&
        css`
            background-color: var(--fg-300);
            :hover {
                background-color: var(--fg-300);
            }
        `}
`

const GreenButton = styled(BaseButton)<ButtonProps>`
    background-color: var(--interface-green);
    color: #fff;

    :hover {
        background-color: var(--interface-green-dark);
    }

    :active,
    :focus {
        border-color: var(--fg-400);
        box-shadow: 0px 0px 0px 4px var(--fg-200);
    }

    ${props =>
        props.disabled &&
        css`
            background-color: var(--fg-300);
            :hover {
                background-color: var(--fg-300);
            }
        `}
`

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ children, variant = ButtonType.Secondary, ...props }, ref) => {
        switch (variant) {
            case ButtonType.Primary:
                return (
                    <PrimaryButton type="button" {...props}>
                        {children}
                    </PrimaryButton>
                )
            case ButtonType.Red:
                return (
                    <RedButton type="button" {...props}>
                        {children}
                    </RedButton>
                )
            case ButtonType.Green:
                return (
                    <GreenButton type="button" {...props}>
                        {children}
                    </GreenButton>
                )
            case ButtonType.Secondary:
                return (
                    <SecondaryButton type="button" {...props}>
                        {children}
                    </SecondaryButton>
                )
        }
    }
)

interface LoadingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode | React.ReactNode[]
    variant?: ButtonType
    isLoading?: boolean
    spinnerColor?: string
    showDot?: boolean
    disabled?: boolean
    onClick?: (event?: React.MouseEvent) => void
}

export const LoadingButton = ({
    variant = ButtonType.Secondary,
    isLoading = false,
    children,
    spinnerColor,
    showDot,
    ...rest
}: LoadingButtonProps) => {
    return (
        <Button
            variant={variant}
            disabled={isLoading}
            {...rest}
            showDot={isLoading ? false : showDot}
        >
            {isLoading && <Spinner />}
            {!isLoading && children}
        </Button>
    )
}

declare const window: any

import { shouldTrackToAnalytics } from "./config"
import { decodeHash, getAnalyticsKey, getUrlParams, log } from "./utils"
import { Events } from "./events"

export const analyticsKey = getAnalyticsKey()

interface PageInfo {
    title?: string
    url?: string
    category?: string
    properties?: Object
    options?: Object
    callback?: () => {}
}

interface TrackProps {}

interface IdentifyProps {
    name?: string
    email?: string
    createdAt?: string
    company?: {
        name: string
        id: string
    }
    "UTM Source"?: any
    "UTM Campaign"?: any
    "UTM Medium"?: any
    "UTM Content"?: any
    "User UTM Source"?: any
    "User UTM Campaign"?: any
    "User UTM Medium"?: any
    "User UTM Content"?: any
}

interface GroupProps {
    company_id?: string
    name?: string
    createdAt?: string
    plan?: string
    licenses?: string
    users?: string
    freeTrial?: boolean
    freeTrialEnd?: string
}

export const logger = {
    breadcrumb: (data, props = {}) => {
        log(data, props, null, "log")
    },
    error: (data, props = {}) => {
        log(data, props, null, "error")
    },
    warning: (data, props = {}) => {
        log(data, props, null, "warn")
    },
    info: (data, props = {}, props2 = {}) => {
        log(data, props, props2, "log")
    },
}

export const analytics = {
    identifyAnonym: (identifyProps?: IdentifyProps) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.identify?.(identifyProps)
            logger.info("Analytics anonym identify", identifyProps)
        }
    },
    identify: (userId: string, identifyProps?: IdentifyProps, options = {}) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.identify(userId, identifyProps, options)
            logger.info("Analytics identify", userId, identifyProps)
        }
    },

    track: (event: any, trackProps?: TrackProps) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.track?.(event, trackProps)
            logger.info("Analytics track", { event, props: trackProps })
        }
    },
    page: (pageName: string, pageInfo?: PageInfo, options = {}) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.page(pageName, pageInfo, options)
            logger.info("Analytics page", pageName)
        }
    },
    group: (groupId: string, groupProps?: GroupProps, options = {}) => {
        if (shouldTrackToAnalytics) {
            window?.analytics?.group(groupId, groupProps, options)
            logger.info("Analytics Group", groupId, groupProps)
        }
    },

    load: session => {
        if (window?.analytics?.load && shouldTrackToAnalytics) {
            window?.analytics?.load(analyticsKey)
            trackFirstTouch(session)
        }
    },
}

const getQueryParams = () => {
    const params = getUrlParams<{
        utm_source?: string
        utm_campaign?: string
        utm_medium?: string
        ref?: string
        source?: string
    }>(window.location.search)

    return params
}

const getReferrer = () => {
    if (document.referrer) {
        return { referrer_url: document.referrer }
    }

    return null
}

const getType = ({ queryParams, referrer }) => {
    if (queryParams.utm_source === "adwords") {
        return { type: "ads", ...queryParams, ...referrer }
    } else if (queryParams.utm_source) {
        return {
            type: "referrer",
            referrer_source: queryParams.utm_source,
            ...referrer,
            ...queryParams,
        }
    } else if (queryParams.ref) {
        return {
            type: "referrer",
            referrer_source: queryParams.ref,
            ...queryParams,
            ...referrer,
        }
    } else if (referrer) {
        return { type: "referrer", ...referrer, ...queryParams }
    }

    return { type: "direct" }
}

const getFirstTouch = () => {
    const queryParams = getQueryParams()
    const referrer = getReferrer()
    const type = getType({ queryParams, referrer })

    return type
}

export const trackFirstTouch = session => {
    if (session) {
        analytics.identify(decodeHash(session?.user?.id), {})
    } else {
        analytics.identifyAnonym(getFirstTouch())
        analytics.track(Events.Attribution, getFirstTouch())
    }
}

import styled from "@emotion/styled"
import { Back } from "@shiftx/icons"
import React from "react"

const BackCircleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: var(--fg-200);
    margin-right: 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--fg-300);
    }
`
export const BackCircle = ({ ...props }) => {
    return (
        <BackCircleContainer {...props}>
            <Back width="12" height="12" />
        </BackCircleContainer>
    )
}

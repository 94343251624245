import styled from "@emotion/styled"
import type { Placement, VirtualElement } from "@popperjs/core"
import React, { FC, Fragment, useCallback, useEffect, useState } from "react"
import { usePopper } from "react-popper"
import { Portal } from "../Portal/Portal"

export type RefCallback<T> = {
    bivarianceHack(instance: T | null): void
}["bivarianceHack"]

export function useStateRef<T>(v: T): [T | null, RefCallback<T>]
export function useStateRef<T>(): [
    T | null | undefined,
    RefCallback<T | undefined>
]
export function useStateRef<T>(
    v?: T
): [T | null | undefined, RefCallback<T | undefined>] {
    const [state, setState] = useState<T | undefined | null>(v)

    const callbackRef: RefCallback<T> = useCallback(v => setState(v), [])

    return [state, callbackRef]
}

const PopperDiv = styled.div`
    z-index: 1000000;
`

export interface PopperProps {
    reference?: Element | VirtualElement | null
    options?: { strategy?: string; placement?: Placement; modifiers?: any }
    alwaysOpen?: boolean
    disablePortal?: boolean
    blocked?: boolean
    onClose?: () => void
    mode?: "click" | "hover"
    showArrow?: boolean
    delay?: boolean
    dependencies?: any
    arrowColor?: string
}

const Container = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1000000;
`

export const Arrow = styled.div<{ arrowColor: string }>`
    width: 16px;
    height: 8px;

    :after {
        content: "";
        position: absolute;
        width: 0px;
        height: 0px;
        box-sizing: border-box;
        border: 8px solid black;
        border-color: transparent transparent ${props => props.arrowColor};
        ${props => props.arrowColor};
        transform-origin: 8px 8px;
        box-shadow: -5px 5px 8px 0 rgba(0, 0, 0, 0.02);
    }

    [data-popper-placement^="bottom"] & {
        top: -8px;

        :after {
            transform: rotate(135deg);
        }
    }

    [data-popper-placement^="top"] & {
        bottom: 0;
        :after {
            transform: rotate(-45deg);
        }
    }

    [data-popper-placement^="right"] & {
        left: -8px;
        :after {
            transform: rotate(45deg);
        }
    }

    [data-popper-placement^="left"] & {
        right: -6px;
        :after {
            transform: rotate(-135deg);
            top: -8px;
        }
    }

    [data-popper-placement^="left-end"] & {
        right: -6px;
        top: auto;
        :after {
            transform: rotate(-135deg);
            top: -8px;
        }
    }
`

export const Popper: FC<PopperProps> = React.memo(
    ({
        reference,
        children,
        options: initialOptions = { modifiers: [], placement: null },
        alwaysOpen = false,
        onClose,
        disablePortal = false,
        blocked = true,
        mode = "click",
        showArrow = false,
        dependencies = [],
        arrowColor = "white",
    }) => {
        const [popper, setPopper] = useStateRef<HTMLDivElement>()
        const [isOpen, setIsOpen] = useState(alwaysOpen)
        const [arrowElement, setArrowElement] = useState(null)
        const [backgroundBlocker, setBackgroundBlocker] = useState(null)

        const { styles, attributes, forceUpdate } = usePopper(
            reference,
            popper,
            {
                placement: initialOptions.placement ?? "top-end",
                modifiers: [
                    {
                        name: "arrow",
                        options: { element: arrowElement },
                        enabled: showArrow,
                    },
                    {
                        name: "offset",
                        options: {
                            offset: [8, 8],
                        },
                    },
                    ...(initialOptions?.modifiers ?? []),
                ],
            }
        )

        useEffect(() => {
            if (dependencies.length > 0) {
                forceUpdate && forceUpdate()
            }
        }, [dependencies])

        const setShowOnHover = event => {
            event.preventDefault()
            event.stopPropagation()
            setIsOpen(true)
        }

        const setLeaveOnHover = () => {
            setIsOpen(false)
            onClose && onClose()
        }

        const closeOnRightClick = event => {
            event.preventDefault()
            setLeaveOnHover()
        }

        useEffect(() => {
            if (reference && !alwaysOpen) {
                const element = reference as Element
                if (mode === "click") {
                    element.addEventListener("click", setShowOnHover)
                } else if (mode === "hover") {
                    element.addEventListener("pointerenter", setShowOnHover)
                    element.addEventListener("pointerleave", setLeaveOnHover)
                }

                if (blocked && backgroundBlocker) {
                    backgroundBlocker.addEventListener(
                        "contextmenu",
                        closeOnRightClick
                    )
                }
            }

            return () => {
                if (backgroundBlocker) {
                    backgroundBlocker.removeEventListener(
                        "contextmenu",
                        closeOnRightClick
                    )
                }

                if (reference && !alwaysOpen) {
                    const element = reference as Element
                    if (mode === "click") {
                        element.removeEventListener("click", setShowOnHover)
                    } else if (mode === "hover") {
                        element.removeEventListener(
                            "pointerenter",
                            setShowOnHover
                        )
                        element.removeEventListener(
                            "pointerleave",
                            setLeaveOnHover
                        )
                    }
                }
            }
        }, [reference, alwaysOpen, blocked, backgroundBlocker])

        useEffect(() => {
            if (blocked && backgroundBlocker) {
                backgroundBlocker.addEventListener(
                    "contextmenu",
                    closeOnRightClick
                )
            }

            return () => {
                if (backgroundBlocker) {
                    backgroundBlocker.removeEventListener(
                        "contextmenu",
                        closeOnRightClick
                    )
                }
            }
        }, [blocked, backgroundBlocker])

        let item = (
            <PopperDiv
                ref={setPopper}
                style={styles.popper}
                onClick={e => {
                    e.stopPropagation()
                }}
                {...attributes.popper}
            >
                {showArrow && (
                    <Arrow
                        ref={setArrowElement}
                        style={styles.arrow}
                        arrowColor={arrowColor}
                    />
                )}

                {typeof children === "function"
                    ? children({ close: setLeaveOnHover })
                    : children}
            </PopperDiv>
        )

        if (blocked) {
            item = (
                <Container
                    ref={setBackgroundBlocker}
                    onClick={event => {
                        event.stopPropagation()
                        setLeaveOnHover()
                    }}
                >
                    {item}
                </Container>
            )
        }

        const animationWrapper = (
            <Fragment>
                {!isOpen && !alwaysOpen ? null : disablePortal ? (
                    item
                ) : (
                    <Portal>{item}</Portal>
                )}
            </Fragment>
        )

        return animationWrapper
    }
)

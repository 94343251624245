import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"

export const TextAreaBase = styled.textarea`
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    box-sizing: border-box;
    border: none;
    resize: none;
    max-width: 100%;
    min-height: 50px;
    width: 100%;
    border-radius: 4px;
    background-color: transparent;

    ::placeholder {
        color: var(--fg-low);
    }

    &:focus {
        outline: none;

        ::placeholder {
            color: var(--fg-low);
            opacity: 0.5;
        }
    }

    :hover:focus {
        background-color: transparent;
    }

    :read-only {
        color: var(--fg-low);
    }
`

export const TextArea = styled(TextAreaBase)`
    border: 1px solid var(--fg-300);
    padding: 8px;

    &:hover {
        border-color: var(--fg-400);
    }

    &:focus {
        border-color: var(--brand-base);
    }
`

export const Events = {
    CreateStep: "createStep",
    ChangeDetailTab: "Change detail tab",
    CreatePath: "createPath",
    CreateSplit: "createSplit",
    CreateReference: "createReference",
    ChangeVersion: "Change version",
    OpenVersion: "Open version",
    CreateProcess: "createProcess",
    CreateVersion: "createVersion",
    CreateIssue: "createIssue",
    CreateFavorite: "createFavorite",
    CreateInvite: "createInvite",
    DeleteStep: "deleteStep",
    DeletePath: "deletePath",
    DeleteSplit: "deleteSplit",
    DeleteReference: "deleteReference",
    DeleteProcess: "deleteProcess",
    DeleteIssue: "DeleteIssue",
    DeleteFavorite: "deleteFavorite",
    DeleteInvite: "deleteInvite",
    ResendInvite: "resendInvite",
    CreateActor: "createActor",
    AddActor: "addActor",
    DownloadPng: "downloadPNG",
    CreateEnd: "createEnd",
    DeleteEnd: "deleteEnd",
    CreatePublicLink: "createPublicLink",
    DeletePublicLink: "deletePublicLink",
    CreateCommentThread: "CreateCommentThread",
    DeleteCommentThread: "DeleteCommentThread",
    CreateReply: "CreateReply",
    DeleteReply: "DeleteReply",
    SelectItem: "SelectItem",
    CreateLoop: "CreateLoop",
    DeleteLoop: "DeleteLoop",
    ChangePlan: "changePlan",
    SignUpStart: "Signup Start",
    SignUpComplete: "Signup Complete",
    Navigate: "Navigate",
    CreateWorkspace: "Create workspace",
    LogIn: "Login",
    ShowModal: "Show modal",
    Filter: "Filter",
    OnboardingStart: "Onboarding start",
    OnboardingStepFinished: "Onboarding step finished",
    OnboardingEnd: "Onboarding end",
    OnboardingSkip: "Onboarding skip",
    SwitchMembership: "Switch membership",
    Purchase: "Purchase",
    EnableGlobalRole: "Enable globalrole",
    ChangeGlobalRole: "Change globalrole",
    AddProcessMember: "Add process member",
    RemoveProcessMember: "Remove process member",
    ChangeProcessMemberRole: "Change process member role",
    CopyEmbedCode: "Copy embed code",
    UpdateStepDuration: "Update step duration",
    UpdateStepDurationUnit: "Update step duration unit",
    UpdateSplitProbability: "Update split probability",
    CreateContentBlock: "Create content block",
    UpdateContentBlock: "Update content block",
    DeleteContentBlock: "Delete content block",
    ShowInviteUser: "Show invite user",
    Attribution: "Attribution",
}

import styled from "@emotion/styled"
import React from "react"
import { severities } from "../../../data/severities"
import { SeverityBadge } from "../../SeverityBadge/SeverityBadge"

const Container = styled.div<{ hidePadding?: boolean }>`
    display: flex;
    align-items: center;
    ${props => !props.hidePadding && `padding: 8px 12px;`}
`

export type SeverityType = typeof severities["blocker"]

export interface IssueSeverityItemProps {
    hidePadding?: boolean
    severity: SeverityType
}

export const SeverityListItem = ({
    severity = null,
    hidePadding,
}: IssueSeverityItemProps) => {
    if (!severity) {
        return null
    }

    return (
        <Container hidePadding={hidePadding}>
            <SeverityBadge severity={severity} />
        </Container>
    )
}

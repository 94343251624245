import { Spinner } from "@shiftx/components"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { Plus, X } from "@shiftx/icons"

interface Props {
    emptyComponent?: React.ReactNode
    children: React.ReactNode
    isOpen?: boolean
    isLoading?: boolean
    isEmpty?: boolean
    onClick?: (event: any) => void
    onClear?: () => void
}

const SpinnerWrapper = styled.div`
    padding: 10px 8px;
`

const StyledWrapper = styled.div<{ isOpen: boolean }>`
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    justify-content: space-between;
    align-items: center;
    border: 1px solid
        ${props => (props.isOpen ? `var(--brand-base)` : `var(--fg-300)`)};
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    width: fit-content;
    &:hover {
        border-color: ${props =>
            props.isOpen ? `var(--brand-base)` : `var(--fg-400)`};
    }

    &:focus {
        border-color: var(--fg-400);
        outline: none;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 12px;
`

const LabelContainer = styled.div`
    margin-left: 8px;
`

const ClearButton = styled.div`
    padding: 10px 12px 10px 0px;
`

export const ListButton = ({ emptyComponent, children = null }) => {
    return (
        <ButtonContainer>
            {typeof emptyComponent === "string" && (
                <Plus width="16" height="16" />
            )}
            {children ? null : (
                <LabelContainer>{emptyComponent}</LabelContainer>
            )}
        </ButtonContainer>
    )
}

export const SingleSelectButtonContainer = ({
    emptyComponent,
    children,
    isOpen,
    onClick,
    isLoading,
    isEmpty,
    onClear,
    ...rest
}: Props) => {
    const hasSelectedItem = !isEmpty
    const isChildrenString = typeof children === "string"

    const child = isChildrenString ? (
        <ButtonContainer>{children}</ButtonContainer>
    ) : (
        children
    )

    return (
        <StyledWrapper
            onClick={onClick}
            onKeyDown={async event => {
                if (event.key === "Enter") {
                    await onClick(event)
                }
            }}
            tabIndex={0}
            isOpen={isOpen}
            {...rest}
        >
            <Fragment>
                {hasSelectedItem ? (
                    child
                ) : (
                    <ListButton emptyComponent={emptyComponent} />
                )}
            </Fragment>

            {isLoading && (
                <SpinnerWrapper>
                    <Spinner size={16} />
                </SpinnerWrapper>
            )}

            {!!onClear && !isEmpty && !isLoading && (
                <ClearButton
                    onClick={event => {
                        event.stopPropagation()
                        onClear()
                    }}
                >
                    <X width={10} height={10} />
                </ClearButton>
            )}
        </StyledWrapper>
    )
}

import { css, Global } from "@emotion/react"
import { interfaceColors, lightTheme } from "@shiftx/styles"
import React from "react"
import { lessThan } from "../utils/breakpoints"

export const GlobalStyle = () => {
    return (
        <Global
            styles={css`
                /* html {
                scroll-behavior: smooth;
            } */

                body {
                    margin: 0px;
                    font-family: Inter, sans-serif;
                    -webkit-tap-highlight-color: transparent;
                    font-size: 16px;
                    line-height: 28px;
                }

                strong {
                    font-weight: 600;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }
                svg {
                    display: block;
                }

                h1,
                h2 {
                    margin: 0;
                }

                div {
                    box-sizing: border-box;
                }

                div:focus {
                    outline: none;
                }

                input,
                textarea {
                    font-family: Inter, sans-serif;
                }

                .noscroll {
                    overflow-y: hidden;
                }

                :root {
                    --brand-base: ${lightTheme.colors.brandBase};
                    --brand-low: ${lightTheme.colors.brandLow};
                    --bg-primary: ${lightTheme.colors.bgPrimary};
                    --fg-100: ${lightTheme.colors.fg100};
                    --fg-200: ${lightTheme.colors.fg200};
                    --fg-300: ${lightTheme.colors.fg300};
                    --fg-400: ${lightTheme.colors.fg400};
                    --fg-low: ${lightTheme.colors.fgLow};
                    --fg-medium: ${lightTheme.colors.fgMedium};
                    --fg-high: ${lightTheme.colors.fgHigh};
                    --interface-red: ${interfaceColors.red.default};
                    --interface-red-lighter: ${interfaceColors.red.lighter};
                    --interface-red-dark: ${interfaceColors.red.darker};
                    --interface-green: ${interfaceColors.green.default};
                    --interface-green-dark: ${interfaceColors.green.darker};
                    --section-margin: 160px;
                    --section-margin-small: 120px;
                    --section-margin-inside: 80px;
                    --grid-gap: 4rem;

                    ${lessThan(1)`
                    --section-margin: 80px;
                    --section-margin-small: 60px;
                    --section-margin-inside: 40px;
                    --grid-gap: 2rem;
                `}
                }
            `}
        />
    )
}

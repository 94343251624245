import styled from "@emotion/styled"
import { Check } from "@shiftx/icons"
import React from "react"
import {
    ActorCategoryListItem,
    ActorListItem,
    SeverityListItem,
    StatusListItem,
    TextListItem,
    UserListItem,
    OrganizationListItem,
    VersionListItem,
} from "../.."
import { StickyItem } from "../SearchList/Components/StickyItem"

export type MenuItemType =
    | "text"
    | "user"
    | "issueStatus"
    | "issueSeverity"
    | "actor"
    | "actorCategory"
    | "organization"
    | "version"

export interface Seperator {
    seperator?: boolean
}

export type DefaultListItem<Item extends {}> = Item & {
    id?: string
    sticky?: boolean
    seperator?: boolean
    subMenu?: any
    shortcut?: "string"
    selected?: boolean
    hide?: boolean
    disabled?: ({ searchValue }?: { searchValue: string }) => boolean
    onSelectItem?: ({
        item,
        searchValue,
        event,
    }: {
        item: Item
        searchValue?: string
        event: React.MouseEvent
    }) => void
}

const MenuItemStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
`
const MenuItemChild = ({ item, type }: { item: any; type: MenuItemType }) => {
    if (item.sticky) {
        return (
            <StickyItem hidePadding={true}>
                {item?.label ?? "Create"}
            </StickyItem>
        )
    } else {
        switch (type) {
            case "text": {
                return (
                    <TextListItem item={item} hidePadding={true}>
                        {item?.label}
                    </TextListItem>
                )
            }
            case "user": {
                return <UserListItem user={item} hidePadding={true} />
            }
            case "issueStatus": {
                return <StatusListItem item={item} hidePadding={true} />
            }
            case "issueSeverity": {
                return <SeverityListItem severity={item} hidePadding={true} />
            }
            case "actor": {
                return <ActorListItem item={item} hidePadding={true} />
            }
            case "actorCategory": {
                return (
                    <ActorCategoryListItem
                        actorCategoryId={item?.id}
                        hidePadding={true}
                    />
                )
            }

            case "organization": {
                return <OrganizationListItem item={item} />
            }
            case "version": {
                return <VersionListItem item={item} />
            }

            default: {
                throw Error("Not support itemType")
            }
        }
    }
}

const RowContainer = styled.div`
    display: flex;
    align-items: center;
`
const CheckContainer = styled.div`
    width: 24px;
`

const ShortCut = styled.div`
    color: var(--fg-low);
`

export const MenuListItem = ({
    item,
    type,
    isSelectedMode = false,
}: {
    item: any
    type: MenuItemType
    isSelectedMode?: boolean
}) => {
    return (
        <MenuItemStyle>
            <RowContainer>
                {isSelectedMode && (
                    <CheckContainer>
                        {item.selected && <Check width={12} height={12} />}
                    </CheckContainer>
                )}

                <MenuItemChild item={item} type={type} />
            </RowContainer>
            <ShortCut>{item.shortcut}</ShortCut>
        </MenuItemStyle>
    )
}

import { Help } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import { motion } from "framer-motion"
import React, { Fragment, useState } from "react"
import { Popper } from "../Popper/Popper"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

const Div = styled.div<{ inline: boolean }>`
    display: ${props => (props.inline ? "inline-block" : "block")};
    ${props =>
        typeof props.children === "string" &&
        css`
            font-style: italic;
            border-bottom: 1px dotted;
        `}
`

interface Props {
    children?: React.ReactNode
    helpText: React.ReactNode
    enabled?: boolean
    inline?: boolean
    animate?: boolean
}

const Container = styled.div`
    background-color: var(--bg-elevated);
    color: var(--fg-medium);
    border-radius: 4px;
    border: 1px solid var(--fg-300);
    padding: 4px 8px;
    ${props => getThemeProp(props.theme, TextStyle.TextXSmall)};
    width: fit-content;
    max-width: 240px;
`

export const HintContainer = ({ animate = true, children, ...props }) => {
    if (!animate) {
        return <Container {...props}>{children}</Container>
    }

    return (
        <motion.div
            transition={{
                delay: 0.3,
                duration: 0.2,
            }}
            initial={{
                opacity: 0,
            }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <Container {...props}>{children}</Container>
        </motion.div>
    )
}

export const Hint = ({
    children,
    helpText,
    enabled = true,
    inline = true,
    animate = true,
    ...props
}: Props) => {
    const [triggerRef, setTriggerRef] = useState(null)

    if (!enabled) {
        return <Fragment>{children}</Fragment>
    }

    return (
        <Fragment>
            <Popper
                mode="hover"
                blocked={false}
                alwaysOpen={false}
                reference={triggerRef}
                options={{
                    placement: "bottom",
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 8],
                            },
                        },
                    ],
                }}
            >
                <HintContainer animate={animate}>{helpText}</HintContainer>
            </Popper>

            <Div inline={inline} ref={setTriggerRef}>
                {children ? (
                    children
                ) : (
                    <Help width={16} height={16} {...props} />
                )}
            </Div>
        </Fragment>
    )
}

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Button, ButtonType } from "@shiftx/components"
import { X } from "@shiftx/icons"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { Fragment, useEffect, useState } from "react"
import { Chevron } from "../icons/Chevron"
import { ShiftX } from "../logos/shiftx"
import { RichText } from "../routes/Case"
import { analytics } from "@shiftx/analytics"
import { greaterThan, lessThan } from "../utils/breakpoints"
import { useIsValidSession } from "../utils/useIsvalidSession"
import { MenuIcon } from "./MenuIcon"
import { Section } from "./Section"

const Container = styled(Section)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const HeaderContainer = styled("header")`
    /*     border: 1px solid rgba(0, 0, 0, 0.06);
 */
    padding: 32px 0;
`

const Menu = styled("ul")`
    display: flex;
    margin: 0;
    list-style: none;
    margin-left: 40px;

    ${lessThan(1)`display: none;`};

    & > li {
        margin-right: 64px;
        position: relative;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        display: block;

        :hover {
            color: var(--brand-base);
        }
    }

    & > li > div:nth-child(2) {
        visibility: hidden;
        position: absolute;
        z-index: 1000;
        width: min-content;

        ul {
            background: #fff;
            list-style: none;
            padding: 0;
            margin-top: 12px;
            border-radius: 6px;
            border: 1px solid var(--fg-200);
            overflow: hidden;
        }
    }

    & > li ul li a {
        display: block;
        padding: 16px;
        font-size: 14px;
        line-height: 20px;

        :hover {
            background-color: #efefef;
        }
    }

    li > div:nth-child(1) svg {
        margin-left: 8px;
    }

    li:hover > div:nth-child(1) {
        svg {
            transform: rotate(180deg);
        }
    }

    li:hover > div:nth-child(2),
    li div:nth-child(2):hover {
        visibility: visible;
        opacity: 1;
        display: block;
    }
`

const FullScreenMenu = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 40px;

    a:active,
    a:focus {
        color: var(--brand-base);
    }

    ul {
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 16px;

        a li {
            display: block;
            padding: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            :active,
            :focus {
                color: var(--brand-base);
            }
        }

        a:not(:last-child) li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }
    }

    & > li {
        margin-top: 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
    }
`

const NestedItemLabel = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: var(--fg-high);
`

const NestedItemText = styled.div`
    margin-top: 4px;
    font-size: 12px;
    line-height: 20px;
    color: var(--fg-medium);
`

export const Header = ({
    isCampaign = false,
    preHeader,
    pages,
    preview = false,
}) => {
    const [showMenu, setShowMenu] = useState(false)
    const [openCollection, setOpenCollection] = useState(null)

    useEffect(() => {
        if (showMenu) {
            document.body.classList.add("noscroll")
        } else {
            if (document.body.classList.contains("noscroll")) {
                document.body.classList.remove("noscroll")
            }
        }

        return () => {
            if (document.body.classList.contains("noscroll")) {
                document.body.classList.remove("noscroll")
            }
        }
    }, [showMenu])

    const router = useRouter()

    if (router?.events) {
        router.events.on("routeChangeComplete", () => {
            setShowMenu(false)
        })
    }

    const isLoggedIn = useIsValidSession()

    return (
        <Fragment>
            {preview && (
                <div
                    css={css`
                        background-color: #000;
                        padding: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 12px;
                    `}
                >
                    Preview.
                </div>
            )}
            {!isCampaign && preHeader.show && (
                <Link href={preHeader.link}>
                    <div
                        css={css`
                            background-color: var(--brand-base);
                            p {
                                margin: 0;
                            }
                            padding: 8px 16px;
                            font-size: 14px;
                            color: #fff;
                            text-align: center;
                            ${preHeader.link &&
                            css`
                                cursor: pointer;
                            `}
                        `}
                    >
                        <RichText content={preHeader.content} />
                    </div>
                </Link>
            )}
            <HeaderContainer>
                {showMenu && (
                    <div
                        css={css`
                            position: fixed;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            background-color: #fff;
                            overflow-y: auto;
                            padding: 32px 24px;
                            z-index: 100;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                            `}
                        >
                            <Link href="/" passHref>
                                <a>
                                    <ShiftX />
                                </a>
                            </Link>
                            <div
                                onClick={() => {
                                    setShowMenu(false)
                                }}
                            >
                                <X width={16} height={16} />
                            </div>
                        </div>

                        <FullScreenMenu>
                            {pages
                                .filter(item => !item.hide)
                                .map((item, index) => {
                                    if (
                                        item.subMenu &&
                                        item.subMenu.length > 0
                                    ) {
                                        return (
                                            <li key={index}>
                                                <div
                                                    onClick={() => {
                                                        if (
                                                            openCollection ===
                                                            item._key
                                                        ) {
                                                            setOpenCollection(
                                                                null
                                                            )
                                                        } else {
                                                            setOpenCollection(
                                                                item._key
                                                            )
                                                        }
                                                    }}
                                                    css={css`
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;

                                                        ${openCollection ===
                                                            item._key &&
                                                        css`
                                                            svg {
                                                                transform: rotate(
                                                                    180deg
                                                                );
                                                            }
                                                        `}
                                                    `}
                                                >
                                                    {item.title} <Chevron />
                                                </div>

                                                {openCollection ===
                                                    item._key && (
                                                    <div>
                                                        <ul>
                                                            {item.subMenu
                                                                .filter(
                                                                    dropdownItem =>
                                                                        !dropdownItem.hide
                                                                )
                                                                .map(
                                                                    (
                                                                        dropdownItem,
                                                                        index2
                                                                    ) => {
                                                                        return (
                                                                            <Link
                                                                                key={
                                                                                    index2
                                                                                }
                                                                                href={
                                                                                    dropdownItem.url
                                                                                }
                                                                                passHref
                                                                            >
                                                                                <a>
                                                                                    <li>
                                                                                        <div>
                                                                                            {
                                                                                                dropdownItem.title
                                                                                            }
                                                                                        </div>
                                                                                        <div>
                                                                                            {
                                                                                                dropdownItem.subTitle
                                                                                            }
                                                                                        </div>
                                                                                    </li>
                                                                                </a>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </li>
                                        )
                                    }

                                    return (
                                        <li key={index}>
                                            {item.url && (
                                                <Link href={item.url}>
                                                    {item.title}
                                                </Link>
                                            )}

                                            {!item.url && item.title}
                                        </li>
                                    )
                                })}
                        </FullScreenMenu>
                        <div
                            css={css`
                                margin-top: 24px;
                                display: flex;
                                flex-direction: column;
                                align-items: left;
                                ${greaterThan(1)`display: none;`};
                            `}
                        >
                            <div
                                css={css`
                                    border-top: 1px solid;
                                    color: ${props =>
                                        props.theme.colors.border[200]};
                                `}
                            />

                            {!isLoggedIn && (
                                <Fragment>
                                    <Link href="/login">
                                        <Button
                                            css={css`
                                                margin-top: 24px;
                                            `}
                                        >
                                            Login
                                        </Button>
                                    </Link>

                                    <Link href="/signup/?plan=teamTrial">
                                        <Button
                                            css={css`
                                                margin-top: 16px;
                                            `}
                                            variant={ButtonType.Primary}
                                            onClick={() => {
                                                analytics.track("Get started", {
                                                    source: "header",
                                                })
                                            }}
                                        >
                                            Get started
                                        </Button>
                                    </Link>
                                </Fragment>
                            )}

                            {isLoggedIn && (
                                <Link href="/dashboard">
                                    <Button
                                        css={css`
                                            margin-top: 24px;
                                        `}
                                        variant={ButtonType.Primary}
                                    >
                                        Dashboard
                                    </Button>
                                </Link>
                            )}
                        </div>
                    </div>
                )}

                <Container>
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        <Link href="/" passHref>
                            <a>
                                <ShiftX />
                            </a>
                        </Link>

                        <Menu>
                            {!isCampaign &&
                                pages
                                    .filter(item => !item.hide)
                                    .map((item, index) => {
                                        if (
                                            item.subMenu &&
                                            item.subMenu.length > 0
                                        ) {
                                            return (
                                                <li key={index}>
                                                    <div
                                                        css={css`
                                                            display: flex;
                                                            align-items: center;
                                                        `}
                                                    >
                                                        {item.title} <Chevron />
                                                    </div>

                                                    <div>
                                                        <ul
                                                            css={css`
                                                                width: 240px;
                                                            `}
                                                        >
                                                            {item.subMenu
                                                                .filter(
                                                                    dropdownItem =>
                                                                        !dropdownItem.hide
                                                                )
                                                                .map(
                                                                    (
                                                                        dropdownItem,
                                                                        index2
                                                                    ) => {
                                                                        return (
                                                                            <Link
                                                                                href={
                                                                                    dropdownItem.url
                                                                                }
                                                                                key={
                                                                                    index2
                                                                                }
                                                                            >
                                                                                <li
                                                                                    css={css`
                                                                                        display: block;
                                                                                        :hover {
                                                                                            background-color: var(
                                                                                                --fg-100
                                                                                            );

                                                                                            ${NestedItemLabel} {
                                                                                                color: var(
                                                                                                    --fg-high
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    `}
                                                                                >
                                                                                    <div
                                                                                        css={css`
                                                                                            padding: 16px
                                                                                                24px;
                                                                                        `}
                                                                                    >
                                                                                        <NestedItemLabel>
                                                                                            {
                                                                                                dropdownItem.title
                                                                                            }
                                                                                        </NestedItemLabel>
                                                                                        <NestedItemText>
                                                                                            {
                                                                                                dropdownItem.subTitle
                                                                                            }
                                                                                        </NestedItemText>
                                                                                    </div>
                                                                                </li>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                )}
                                                        </ul>
                                                    </div>
                                                </li>
                                            )
                                        }

                                        return (
                                            <li key={index}>
                                                {item.url && (
                                                    <Link href={item.url}>
                                                        {item.title}
                                                    </Link>
                                                )}

                                                {!item.url && item.title}
                                            </li>
                                        )
                                    })}
                        </Menu>
                    </div>

                    <div
                        onClick={() => {
                            setShowMenu(true)
                        }}
                        css={css`
                            ${greaterThan(1)`display: none;`};
                        `}
                    >
                        <MenuIcon />
                    </div>

                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            ${lessThan(1)`display: none;`};
                        `}
                    >
                        {!isCampaign && !isLoggedIn && (
                            <Link href="/login">
                                <Button>Login</Button>
                            </Link>
                        )}

                        {(!isLoggedIn || isCampaign) && (
                            <Link href="/signup">
                                <Button
                                    css={css`
                                        margin-left: 8px;
                                    `}
                                    variant={ButtonType.Primary}
                                    onClick={() => {
                                        analytics.track("Get started", {
                                            source: "header",
                                        })
                                    }}
                                >
                                    Get started
                                </Button>
                            </Link>
                        )}
                        {isLoggedIn && !isCampaign && (
                            <Link href="/dashboard">
                                <Button variant={ButtonType.Primary}>
                                    Dashboard
                                </Button>
                            </Link>
                        )}
                    </div>
                </Container>
            </HeaderContainer>
        </Fragment>
    )
}

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ButtonType, Input, LoadingButton } from "@shiftx/components"
import React, { Fragment, useState } from "react"
import { useForm } from "react-hook-form"
import sanity from "../config/sanity"
import { BodyText } from "../src/components/BodyText"
import { Footer } from "../src/components/Footer"
import { Main } from "../src/components/Main"
import Meta from "../src/components/Meta"
import { Section } from "../src/components/Section"
import { Title2, Title3 } from "../src/components/Title"
import { bookDemo } from "../src/utils/newsletter"

export const Error = styled.div`
    font-size: 12px;
    margin-top: 4px;
    color: red;
`

const Demo = ({ demo: content }) => {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [hasError, setHasError] = useState(false)

    const onSubmit = async ({ name, email, title, company, size }) => {
        const respons = await bookDemo({ name, email, title, company, size })
        if (respons.data.demoRequest.ok) {
            setIsSubmitted(true)
            setHasError(false)
        } else {
            setHasError(true)
        }
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting },
        setValue,
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
            title: "",
            company: "",
            size: "0",
        },
    })

    return (
        <Fragment>
            {content?.meta && (
                <Meta
                    title={content?.meta.title ?? content.title}
                    {...content.meta}
                />
            )}

            <Main>
                <Section
                    css={css`
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fit,
                            minmax(18rem, 1fr)
                        );
                        grid-gap: var(--grid-gap);
                    `}
                >
                    <div>
                        <Title2
                            css={css`
                                margin-bottom: 24px;
                            `}
                        >
                            {content.title}
                        </Title2>
                        <BodyText content={content.body} />
                    </div>

                    <div
                        css={css`
                            background: rgba(0, 0, 0, 0.03);
                            border-radius: 8px;
                            padding: 60px;
                            input {
                                background-color: #fff;
                            }
                        `}
                    >
                        <Title3>{content.formTitle}</Title3>

                        {!isSubmitted && (
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                css={css`
                                    & > div {
                                        margin-bottom: 16px;
                                    }
                                `}
                            >
                                <h2
                                    css={css`
                                        margin-top: 40px;
                                        margin-bottom: 8px;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 28px;
                                    `}
                                >
                                    Personal info
                                </h2>
                                <div>
                                    <Input
                                        placeholder="Full name"
                                        {...register("name", {
                                            required: true,
                                        })}
                                    />
                                    {errors.name && (
                                        <Error>Full name is required</Error>
                                    )}
                                </div>

                                <div>
                                    <Input
                                        placeholder="Business email"
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message: "Email is required",
                                            },
                                            pattern: {
                                                value: /\S+@\S+.\S+/,
                                                message:
                                                    "Entered value does not match email format",
                                            },
                                        })}
                                    />
                                    {errors.email && (
                                        <Error>{errors.email.message}</Error>
                                    )}
                                </div>
                                <div>
                                    <Input
                                        placeholder="Business title"
                                        {...register("title")}
                                    />

                                    {errors.title && <Error>Error</Error>}
                                </div>

                                <h2
                                    css={css`
                                        margin-top: 40px;
                                        margin-bottom: 8px;
                                        font-weight: 500;
                                        font-size: 14px;
                                        line-height: 28px;
                                    `}
                                >
                                    Company info
                                </h2>
                                <div>
                                    <Input
                                        placeholder="Company name"
                                        {...register("company", {
                                            required: true,
                                        })}
                                    />
                                    {errors.company && (
                                        <Error>Company name is required</Error>
                                    )}
                                </div>
                                <div>
                                    <select
                                        {...register("size")}
                                        css={css`
                                            padding: 8px;
                                            border: 1px solid var(--fg-300);
                                            color: var(--fg-medium);
                                        `}
                                    >
                                        <option disabled selected value="0">
                                            Company size
                                        </option>
                                        <option value="1-10">1 - 10</option>
                                        <option value="11-50">11 - 50</option>
                                        <option value="51-200">51 - 200</option>
                                        <option value="201-500">
                                            201 - 500
                                        </option>
                                        <option value="501-1000">
                                            501 - 1000
                                        </option>
                                        <option value="Over 1000">
                                            Over 1000
                                        </option>
                                    </select>
                                    {errors.size && <Error>Error</Error>}
                                </div>

                                <LoadingButton
                                    isLoading={isSubmitting}
                                    variant={ButtonType.Primary}
                                    type="submit"
                                >
                                    {content.buttonText}
                                </LoadingButton>
                            </form>
                        )}

                        {isSubmitted && (
                            <p>
                                Thank you for showing an interest in ShiftX! You
                                will hear back from us on email within one
                                business day
                            </p>
                        )}
                    </div>
                </Section>
            </Main>
            <Footer />
        </Fragment>
    )
}

const demoQuery = `*[_type == "demo"] {
   ...
  }[0]
  `

// This function gets called at build time on server-side.
export const getStaticProps = async () => {
    const demo = await sanity.fetch(demoQuery)
    return { props: { demo } }
}

export default Demo

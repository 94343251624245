import styled from "@emotion/styled"
import { lessThan } from "../utils/breakpoints"

export const Title = styled.h1`
    font-weight: bold;
    font-size: 64px;
    line-height: 80px;
    color: rgba(0, 0, 0, 0.87);

    ${lessThan(1)`
        font-size: 40px;
        line-height: 60px;
    `}
`

export const Title2 = styled.h1`
    font-size: 40px;
    line-height: 56px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;

    ${lessThan(1)`
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
    `}
`

export const Title3 = styled.h2`
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.87);

    ${lessThan(1)`
        font-size: 16px;
        line-height: 28px;
    `}
`

export const Title4 = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;

    ${lessThan(1)`
        font-size: 16px;
        line-height: 28px;
    `}
`

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Card } from "@shiftx/components"
import { motion } from "framer-motion"
import React from "react"
import FocusLock from "react-focus-lock"
import { useHotkeys } from "../../utils/useHotkey"

const ModalWrapper = styled.div<{
    showBackground?: boolean
}>`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    padding: 0;
    z-index: 1000000;
`

interface Props {
    header?: React.ReactNode
    footer?: React.ReactNode
    closeFn?: () => void
    children: React.ReactNode
    scrollable?: boolean
    disableFocusLock?: boolean
    closeOnOutesideClick?: boolean
    showBackground?: boolean
}

const ScrollWrapper = styled.div<{ scrollable?: boolean }>`
    overflow-y: ${props => (props.scrollable ? "scroll" : "visible")};
    max-width: 100%;
    max-height: 90vh;
    width: 400px;
`

const BackgroundContainer = styled(motion.div)`
    background-color: rgba(0, 0, 0, 0.15);
    position: fixed;
    inset: 0;
    z-index: -1;
`

export const Modal = ({
    header = null,
    footer = null,
    closeFn,
    children,
    scrollable = true,
    disableFocusLock = false,
    closeOnOutesideClick = false,
    showBackground = true,
    ...rest
}: Props) => {
    useHotkeys("esc", () => {
        closeFn()
    })

    return (
        <ModalWrapper
            showBackground={showBackground}
            onKeyDown={e => {
                if (e.key === "Escape") {
                    closeFn()
                }
            }}
            onClick={() => {
                if (closeOnOutesideClick) {
                    closeFn()
                }
            }}
        >
            {showBackground && (
                <BackgroundContainer
                    transition={{
                        duration: 0.1,
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            duration: 0.1,
                        },
                    }}
                    animate={{ opacity: 1 }}
                ></BackgroundContainer>
            )}

            <motion.div
                transition={{
                    duration: 0.2,
                }}
                initial={{
                    opacity: 0,
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        duration: 0.1,
                    },
                }}
                animate={{ opacity: 1 }}
            >
                <FocusLock
                    group="group42"
                    disabled={disableFocusLock}
                    noFocusGuards={false}
                    returnFocus={true}
                >
                    <Card
                        css={css`
                            border: none;
                        `}
                        shadow={true}
                    >
                        <ScrollWrapper
                            scrollable={scrollable}
                            onClick={e => e.stopPropagation()}
                            {...rest}
                        >
                            <div>{header}</div>
                            {children}
                        </ScrollWrapper>
                        {footer}
                    </Card>
                </FocusLock>
            </motion.div>
        </ModalWrapper>
    )
}

import styled from "@emotion/styled"
import { ChevronRight } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React, { useEffect, useRef, useState } from "react"

export const AccordionTitle = styled.div`
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    font-weight: 600;
`

export const AccordionContent = styled.div`
    padding: 0px 16px 16px;
`

export const RootTitle = styled.div<{ collapsed: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;

    svg {
        ${props =>
            props.collapsed
                ? "transform: rotate(90deg)"
                : "transform: rotate(270deg)"};
    }
`

export const AccordionItem = ({ children, collapsed = true }) => {
    return <div>{children}</div>
}

const AccoridonCollapse = styled.div<{ collapsed: boolean }>`
    border-bottom: 1px solid var(--fg-300);

    ${props =>
        props.collapsed &&
        `height: 0;
    overflow: hidden;`}
`

const Row = ({ child }) => {
    const title = child.props.children[0]
    const content = child.props.children[1]

    const [collapsed, setCollapsed] = useState(child.props?.collapsed ?? true)

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (!collapsed) {
            ref.current.scrollIntoView(true)
        }
    }, [collapsed])
    return (
        <div ref={ref}>
            <RootTitle
                collapsed={collapsed}
                onClick={() => {
                    setCollapsed(state => !state)
                }}
            >
                {title}
                <ChevronRight width={16} height={16} />
            </RootTitle>
            <AccoridonCollapse collapsed={collapsed}>
                {content}
            </AccoridonCollapse>
        </div>
    )
}

export const Accordion = ({ children }) => {
    return React.Children.map(children, child => {
        return <Row child={child} />
    })
}

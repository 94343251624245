import styled from "@emotion/styled"
import { Plus } from "@shiftx/icons"
import React from "react"

const ListItemContainer = styled("div")<{ hidePadding?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${props => !props.hidePadding && `padding: 10px 12px;`}
`

const ListLabel = styled.div`
    margin-left: 8px;
    flex: 1;
`

export const StickyItem = ({ children, hidePadding = false }) => {
    return (
        <ListItemContainer hidePadding={hidePadding}>
            <Plus width={12} height={12} />
            <ListLabel>{children}</ListLabel>
        </ListItemContainer>
    )
}

import { keyframes } from "@emotion/react"
import { Spinner as Icon } from "@shiftx/icons"
import React from "react"
import styled from "@emotion/styled"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const SpinnerAnimation = styled.div`
    animation: ${rotate} 1s linear infinite;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Spinner = ({ size = 20, ...props }) => {
    return (
        <SpinnerAnimation {...props}>
            <Icon width={size} height={size} />
        </SpinnerAnimation>
    )
}

module.exports = {
    // Find your project ID and dataset in `sanity.json` in your studio project
    projectId: "uq4w4z8u",
    dataset: "production",
    useCdn: false,
    apiVersion: "v1",
    // useCdn == true gives fast, cheap responses using a globally distributed cache.
    // Set this to false if your application require the freshest possible
    // data always (potentially slightly slower and a bit more expensive).
}

import styled from "@emotion/styled"
import { OrganizationAvatar } from "@shiftx/components"

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

const Label = styled.div`
    margin-left: 12px;
`
export interface OranizationItem {
    organization: { name: string }
}
interface OrganizationItemType {
    item: OranizationItem
}

export const OrganizationListItem = ({ item }: OrganizationItemType) => {
    return (
        <Container>
            <OrganizationAvatar
                organization={{
                    name: item.organization.name,
                }}
                size={20}
            />
            <Label>{item.organization.name}</Label>
        </Container>
    )
}

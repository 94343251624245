import { css } from "@emotion/react"
import { ButtonType } from "@shiftx/components"
import Link from "next/link"
import React from "react"
import { greaterThan } from "../utils/breakpoints"
import { Button } from "./Button"
import { Section } from "./Section"
import { Title2 } from "./Title"

export const SeeForYourself = ({
    button = { label: "Get started", link: "/pricing/" },
}) => {
    return (
        <div
            css={css`
                background-color: #e8eefd;
                margin-top: var(--section-margin);
                padding: 120px 0;
            `}
        >
            <Section
                css={css`
                    text-align: center;
                `}
            >
                <Title2>Try ShiftX for free</Title2>
                <p
                    css={css`
                        margin: 0 auto;
                        margin-top: 24px;
                        margin-bottom: 40px;
                        ${greaterThan(0)` max-width: 50%;`}
                    `}
                >
                    Set up your own single-user, free-forever account, or try
                    ShiftX free for 14 days with the rest of your colleagues.
                </p>
                <Link href={button.link} passHref>
                    <a>
                        <Button variant={ButtonType.Primary}>
                            {button.label}
                        </Button>
                    </a>
                </Link>
            </Section>
        </div>
    )
}

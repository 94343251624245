import styled from "@emotion/styled"

export const HorizontalBorder = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--fg-200);
`

export const VerticalBorder = styled.div`
    height: 100%;
    width: 1px;
    background-color: var(--fg-200);
`

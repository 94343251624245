import React from "react"
import { css } from "@emotion/react"
import { greaterThan } from "../utils/breakpoints"

const serializers = {
    types: {
        youtube: ({ node }) => {
            const { url } = node
            return (
                <div
                    css={css`
                        position: relative;
                        padding-bottom: 56.25%; /* 16:9 */
                        height: 0;
                    `}
                >
                    <iframe
                        css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: none;
                        `}
                        frameborder={0}
                        src={url}
                        allowFullScreen
                    ></iframe>
                </div>
            )
        },
        shiftxFlow: ({ node }) => {
            const { url } = node
            return (
                <div
                    css={css`
                        position: relative;
                        padding-bottom: 56.25%; /* 16:9 */
                        height: 0;
                        ${greaterThan(
                            1
                        )`margin-left: calc(-100vw / 2 + 720px / 2);
                        margin-right: calc(-100vw / 2 + 720px / 2);`}
                    `}
                >
                    <iframe
                        css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border: none;
                        `}
                        src={`https://shiftx.com/embed?url=${decodeURI(url)}`}
                        frameborder={0}
                        allowFullScreen
                    ></iframe>
                </div>
            )
        },
    },
}

export default serializers

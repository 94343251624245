import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Dots } from "@shiftx/icons"
import React from "react"
import { Button, SearchListItemContainer, SearchList, usePopover } from "../.."
import { ActorCategories } from "../../data/actorCategories"
import { MenuList, MenuListProps } from "../MenuList/MenuList"
import { MenuItemType, MenuListItem } from "../MenuList/MenuListItem"
import { PopoverContent } from "../Popover/PopoverContent"
import { ActorItem } from "../SearchList/Components/ActorListItem"
import { OranizationItem } from "../SearchList/Components/OrganizationListItem"
import { SeverityType } from "../SearchList/Components/SeverityListItem"
import { IssueStatusItemType } from "../SearchList/Components/StatusListItem"
import { TextListItemType } from "../SearchList/Components/TextListItem"

export interface DropdownProps<NS, Item> extends MenuListProps<Item> {
    useSearch?: boolean
    children?: any
    searchKey?: string
    initialId?: string
    itemType: NS
    strategy?: "absolute" | "fixed"
    dataTestId?: string
}

type INamespaceKeyMap2 = Record<"issueSeverity", SeverityType> &
    Record<"text", TextListItemType> &
    Record<"issueStatus", IssueStatusItemType> &
    Record<"actor", ActorItem> &
    Record<"actorCategory", ActorCategories> &
    Record<"user", any> &
    Record<"organization", OranizationItem> &
    Record<"version", any>

export type INamespaceKeys<T extends MenuItemType> = INamespaceKeyMap2[T]

const ButtonContainer = styled.div`
    display: flex;
`

export const DropdownMenu = <
    Type extends MenuItemType,
    Item extends INamespaceKeys<Type>
>({
    useSearch = true,
    searchKey = "label",
    children = (
        <Button>
            <Dots width={16} height={16} />
        </Button>
    ),
    initialId,
    items,
    onSelectItem,
    itemType = "text" as Type,
    strategy = "absolute",
    dataTestId,
}: DropdownProps<Type, Item>) => {
    const popover = usePopover()

    return (
        <div>
            <ButtonContainer ref={popover.setReference("dropDownMenu")}>
                <div
                    data-testid={dataTestId}
                    onClick={event => {
                        event.preventDefault()
                        event.stopPropagation()
                        popover.setShow("dropDownMenu")
                    }}
                    css={css`
                        min-width: 0;
                    `}
                >
                    {typeof children === "function"
                        ? children({ isActive: popover.show })
                        : children}
                </div>
            </ButtonContainer>

            <PopoverContent
                id="dropDownMenu"
                block={true}
                {...popover}
                placement="bottom-start"
                offset={[0, 8]}
                strategy={strategy}
            >
                {!useSearch && (
                    <MenuList
                        showShadow={true}
                        items={items}
                        initialSelectedItemId={initialId}
                        onSelectItem={({ item, event }) => {
                            onSelectItem && onSelectItem({ item, event })
                            popover.hide()
                        }}
                        itemComp={props => {
                            return (
                                <SearchListItemContainer
                                    {...props}
                                    showPadding={true}
                                >
                                    <MenuListItem type={itemType} {...props} />
                                </SearchListItemContainer>
                            )
                        }}
                    />
                )}

                {useSearch && (
                    <SearchList
                        showShadow={true}
                        items={items}
                        initialSelectedItemId={initialId}
                        onSelectItem={({ item, event }) => {
                            onSelectItem && onSelectItem({ item, event })
                            popover.hide()
                        }}
                        searchKey={searchKey}
                        itemComp={props => {
                            return (
                                <SearchListItemContainer
                                    {...props}
                                    showPadding={true}
                                >
                                    <MenuListItem type={itemType} {...props} />
                                </SearchListItemContainer>
                            )
                        }}
                    />
                )}
            </PopoverContent>
        </div>
    )
}

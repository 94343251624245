import * as React from "react"

function SvgCheck(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21 5L9 19l-6-6"
                stroke="currentColor"
                strokeOpacity={0.87}
                strokeWidth={3}
            />
        </svg>
    )
}

export default SvgCheck

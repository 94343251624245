export const gray = {
    100: "#181822",
    80: "#3F3F50",
    64: "#484A5B",
    48: "#6C7489",
    32: "#95A0B2",
    24: "#C3CBD5",
    16: "#D9E1E8",
    12: "#E6EBEF",
    8: "#F2F5F7", //
    4: "#F8F9FB", //
    2: "#FCFCFD",
    0: "#FFFFFF", //
}

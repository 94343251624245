import styled from "@emotion/styled"

export const Input = styled.input<{
    bg?: string
    error?: boolean
    theme?: any
}>`
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    box-sizing: border-box;
    appearance: none;
    height: 36px;
    min-width: 160px;

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.24);
    }

    &:focus {
        outline: none;
        border: 1px solid rgb(64, 64, 240);

        ::placeholder {
            color: rgba(0, 0, 0, 0.24);
            opacity: 0.5;
        }
    }

    ::placeholder {
        color: rgba(0, 0, 0, 0.24);
    }

    :disabled {
        background-color: rgba(0, 0, 0, 0.24);
        border-color: rgba(0, 0, 0, 0.24);
        color: rgba(0, 0, 0, 0.24);
    }
`

import { notationColors } from "@shiftx/styles"

export interface ActorCategories {
    id: string
    name: string
    color: string
    colorTheme: any
}

export const actorCategories: ActorCategories[] = [
    {
        id: "customercontext",
        name: "Customer Context",
        color: notationColors.turquoise.default,
        colorTheme: notationColors.turquoise,
    },
    {
        id: "customer",
        name: "Customer",
        color: notationColors.pink.default,
        colorTheme: notationColors.pink,
    },
    {
        id: "reseller",
        name: "Reseller",
        color: notationColors.green.default,
        colorTheme: notationColors.green,
    },
    {
        id: "company",
        name: "Company",
        color: notationColors.blue.default,
        colorTheme: notationColors.blue,
    },
    {
        id: "system",
        name: "System",
        color: notationColors.yellow.default,
        colorTheme: notationColors.yellow,
    },
    {
        id: "supplier",
        name: "Supplier",
        color: notationColors.purple.default,
        colorTheme: notationColors.purple,
    },
    {
        id: "competitor",
        name: "Competitor",
        color: notationColors.red.default,
        colorTheme: notationColors.red,
    },
    {
        id: "government",
        name: "Government",
        color: notationColors.brown.default,
        colorTheme: notationColors.brown,
    },
    {
        id: "custom",
        name: "Other",
        color: notationColors.gray.default,
        colorTheme: notationColors.gray,
    },
]

import type { lightTheme } from "@shiftx/styles"
import { css } from "@emotion/react"

export const getGray = gray => props => {
    return props.theme["gray"] && props.theme["gray"][gray]
}

type colorsKey = keyof typeof lightTheme.colors

export const getColor = (theme, type: colorsKey) => {
    return theme?.["colors"]?.[type]
}

export const getThemeProp = (theme, prop) => {
    return css`
        ${theme[prop]}
    `
}

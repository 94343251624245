import styled from "@emotion/styled"
import React from "react"

const VersionContainer = styled.div<{ isLast?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

const VersionIcon = styled.div`
    margin-right: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid var(--fg-400);
`

const VersionLabel = styled.div`
    display: flex;
    align-items: center;
`

export const VersionListItem = ({ item }) => {
    return (
        <VersionContainer>
            <VersionLabel>
                <VersionIcon />
                Version {item.version} {item.isFirst && "(current)"}
            </VersionLabel>
        </VersionContainer>
    )
}

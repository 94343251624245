import { css } from "@emotion/react"
import React from "react"

export const Video = React.forwardRef(
    ({ src, width = 0, height = 0, poster = null, asset, ...props }, video) => {
        const videoAspectRatio = asset.width / asset.height
        const containerAspectRation = width / height

        const different = videoAspectRatio - containerAspectRation

        if (width && height) {
            return (
                <div
                    css={css`
                        display: relative;
                    `}
                >
                    <div
                        css={css`
                            display: block;
                            box-sizing: border-box;
                            padding-top: ${(100 / width) * height}%;
                        `}
                    ></div>

                    <div
                        css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                        `}
                    >
                        <video
                            ref={video}
                            width={`${
                                100 + 100 / (different > 0 ? different : 0)
                            }`}
                            autoPlay={false}
                            loop
                            muted={true}
                            poster={poster}
                            playsInline={true}
                        >
                            <source src={src}></source>
                        </video>
                    </div>
                </div>
            )
        } else {
            return (
                <video
                    ref={video}
                    width={`100%`}
                    autoPlay={false}
                    loop
                    muted={true}
                    poster={poster}
                    playsInline={true}
                    {...props}
                >
                    <source src={src}></source>
                </video>
            )
        }
    }
)

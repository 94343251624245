import { css } from "@emotion/react"
import React from "react"
import { Image } from "./Image"
import { Video } from "./Video"

export const Asset = ({ asset, ...props }) => {
    if (asset?._type === "cloudinary" || asset?._type === "cloudinary.asset") {
        if (asset.resource_type === "video") {
            return (
                <Video
                    src={asset.secure_url}
                    asset={asset}
                    autoPlay={true}
                    playsInline={true}
                    {...props}
                />
            )
        } else if (asset.resource_type === "image") {
        }
    } else if (asset?._type === "image") {
        return <Image image={asset} alt={asset.alt} {...props} />
    } else if (asset?._type === "youtube") {
        // console.log("asset", asset)
        return (
            <div
                css={css`
                    position: relative;
                    padding-bottom: 56.25%; /* 16:9 */
                    height: 0;
                    border: 1px solid var(--fg-100);
                    border-radius: 24px;
                    overflow: hidden;
                `}
            >
                <iframe
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: none;
                    `}
                    frameborder={0}
                    src={asset.url}
                    allowFullScreen
                ></iframe>
            </div>
        )
    }

    return null
}

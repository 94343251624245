import { ThemeProvider } from "@emotion/react"
import * as Sentry from "@sentry/browser"
import { analytics } from "@shiftx/analytics"
import { useRouter } from "next/router"
import React, { useEffect } from "react"
import { ModalProvider } from "react-modal-hook"
import sanity from "../config/sanity"
import { Header } from "../src/components/Header"
import { GlobalStyle } from "../src/theme/global"
import { publicTheme } from "../src/theme/publicTheme"
import { isProd } from "../src/utils/isProd"
import { useGetSession } from "../src/utils/useIsvalidSession"

const isProduction = isProd()

Sentry.init({
    dsn: "https://af5693f548b44a599b675c666e867f3f@sentry.io/1232704",
    integrations: !isProduction
        ? [
              new Sentry.Integrations.Breadcrumbs({
                  console: false,
              }),
          ]
        : [],
    beforeSend: (event, hint) => {
        if (!isProduction) {
            console.error(
                hint.originalException || hint.syntheticException || event
            )
            return null // this drops the event and nothing will be sent to sentry
        }
        return event
    },
})

const AppOverride = ({ Component, pageProps, appProps, ...rest }) => {
    const session = useGetSession()
    const router = useRouter()

    useEffect(() => {
        analytics.load(session)
    }, [session])

    useEffect(() => {
        analytics.page(window.location.pathname)

        const handleRouteChange = (url, { shallow }) => {
            analytics.page(url)
        }

        router.events.on("routeChangeComplete", handleRouteChange)

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange)
        }
    }, [])

    return (
        <ThemeProvider theme={publicTheme}>
            <GlobalStyle />
            <ModalProvider>
                <Header
                    preHeader={pageProps.global.preHeader}
                    pages={pageProps.global.pages}
                    preview={pageProps?.sanityDoc?.__preview__}
                    isCampaign={pageProps?.content?._type === "campaignsite"}
                />
                <Component {...appProps} {...pageProps} />
            </ModalProvider>
        </ThemeProvider>
    )
}

const globalQuery = `*[_type == "navigation"][0]{preHeader, pages}`

AppOverride.getInitialProps = async () => {
    const global = await sanity.fetch(globalQuery)

    return {
        pageProps: { global },
    }
}

export default AppOverride

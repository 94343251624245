import styled from "@emotion/styled"
import { Exclamation, X } from "@shiftx/icons"
import { motion } from "framer-motion"
import React, { useEffect } from "react"
import { Portal } from "../Portal/Portal"

const ToastContainer = styled(motion.div)`
    position: absolute;
    bottom: 24px;
    right: 24px;
    max-width: 300px;
    max-height: 100px;
    background-color: red;
    z-index: 1000000;
    padding: 16px;
    border-radius: 6px;
    background-color: var(--bg-elevated);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
`

const Message = styled.div`
    margin: 0 12px;
    flex: 1;
`
const MessageContainer = styled.div`
    display: flex;
`

const Error = styled.div`
    color: var(--interface-red);
`
const Info = styled.div`
    color: var(--interface-blue);
`

const Close = styled.div`
    cursor: pointer;
`
let timout = null

interface Props {
    message: string
    type?: "error" | "info"
    onClose?: () => void
}
export const Toast = ({ message, type = "info", onClose }: Props) => {
    useEffect(() => {
        if (onClose) {
            timout = setTimeout(() => {
                onClose && onClose()
                if (timout && onClose) {
                    clearTimeout(timout)
                }
            }, 5000)
        }
    }, [])

    if (!message) {
        return null
    }

    return (
        <Portal>
            <ToastContainer
                initial={{ y: 200 }}
                animate={{
                    y: 0,
                    transition: {
                        duration: 0.3,
                    },
                }}
                exit={{
                    y: 200,
                    transition: {
                        duration: 0.3,
                    },
                }}
            >
                <MessageContainer>
                    {type === "error" && (
                        <Error>
                            <Exclamation width={20} height={20} />
                        </Error>
                    )}
                    {type === "info" && (
                        <Info>
                            <Exclamation width={20} height={20} />
                        </Info>
                    )}

                    <Message>{message}</Message>
                    <Close
                        onClick={() => {
                            onClose()
                            clearTimeout(timout)
                        }}
                    >
                        <X width={12} height={12} />
                    </Close>
                </MessageContainer>
            </ToastContainer>
        </Portal>
    )
}

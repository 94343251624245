import React from "react"
import { Hint } from "../Hint/Hint"
import { ToDo, InProgress, Blocked, Canceled, Done } from "@shiftx/icons"

export enum IssueStatus {
    Blocked = "blocked",
    Todo = "open",
    InProgress = "inProgress",
    Solved = "solved",
    Discarded = "discarded",
}
export const issueStatus = [
    { id: IssueStatus.Blocked, label: "Blocked" },
    { id: IssueStatus.Todo, label: "To do" },
    { id: IssueStatus.InProgress, label: "In progress" },
    { id: IssueStatus.Solved, label: "Done" },
    { id: IssueStatus.Discarded, label: "Canceled" },
]

const issueStatusIcon = id => {
    switch (id) {
        case "open":
            return <ToDo width={16} height={16} />

        case "inProgress":
            return <InProgress width={16} height={16} />

        case "solved":
            return <Done width={16} height={16} />

        case "blocked":
            return <Blocked width={16} height={16} />

        case "discarded":
            return <Canceled width={16} height={16} />

        default:
            break
    }
}

const getLabel = id => {
    return issueStatus.find(status => status.id === id).label
}

export const IssueStatusIcon = ({ id, hint = false }) => {
    if (hint) {
        return <Hint helpText={getLabel(id)}>{issueStatusIcon(id)}</Hint>
    } else {
        return issueStatusIcon(id)
    }
}

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Card, CardBody, CardHeader } from "@shiftx/components"
import { X } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import { markInputRule } from "@tiptap/core"
import { motion } from "framer-motion"
import React from "react"
import FocusLock from "react-focus-lock"
import { useHotkeys } from "../../utils/useHotkey"

const ModalWrapper = styled.div<{
    showBackground?: boolean
}>`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    padding: 0;
    z-index: 1000000;
`

interface Props {
    closeFn?: () => void
    children: React.ReactElement
    sidePanel: {
        header: {
            icon?: React.ElementType
            title?: string
        }
        body: React.ReactElement
    }
    scrollable?: boolean
    disableFocusLock?: boolean
    closeOnOutesideClick?: boolean
    showBackground?: boolean
}

const ScrollWrapper = styled.div<{ scrollable?: boolean }>`
    overflow-y: ${props => (props.scrollable ? "scroll" : "visible")};
    max-width: 100%;
    max-height: 90vh;
    width: 400px;
`

const BackgroundContainer = styled(motion.div)`
    background-color: rgba(0, 0, 0, 0.15);
    position: fixed;
    inset: 0;
    z-index: -1;
`

export const SplitModal = ({
    closeFn,
    children: mainPanel,
    sidePanel: {
        header: { icon: Icon, title },
        body: sidePanelBody,
    },
    scrollable = true,
    disableFocusLock = false,
    closeOnOutesideClick = false,
    showBackground = true,
    ...rest
}: Props) => {
    useHotkeys("esc", () => {
        closeFn()
    })

    return (
        <ModalWrapper
            showBackground={showBackground}
            onKeyDown={e => {
                if (e.key === "Escape") {
                    closeFn()
                }
            }}
            onClick={() => {
                if (closeOnOutesideClick) {
                    closeFn()
                }
            }}
        >
            {showBackground && (
                <BackgroundContainer
                    transition={{
                        duration: 0.1,
                    }}
                    initial={{
                        opacity: 0,
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            duration: 0.1,
                        },
                    }}
                    animate={{ opacity: 1 }}
                ></BackgroundContainer>
            )}

            <motion.div
                transition={{
                    duration: 0.2,
                }}
                initial={{
                    opacity: 0,
                }}
                exit={{
                    opacity: 0,
                    transition: {
                        duration: 0.1,
                    },
                }}
                animate={{ opacity: 1 }}
                {...rest}
            >
                {/* <FocusLock
                    group="group42"
                    disabled={disableFocusLock}
                    noFocusGuards={false}
                    returnFocus={true}
                > */}
                <div
                    css={css`
                        width: 90vw;
                        height: 90vh;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            column-gap: 16px;
                            width: 100%;
                            height: 100%;
                        `}
                    >
                        <div
                            css={css`
                                flex: 1;
                            `}
                        >
                            <Card
                                css={css`
                                    display: flex;
                                    width: 100%;
                                    height: 100%;
                                    overflow: hidden;
                                `}
                                shadow={true}
                            >
                                {mainPanel}
                            </Card>
                        </div>
                        <div
                            css={css`
                                width: 320px;
                            `}
                        >
                            <Card
                                shadow={true}
                                css={css`
                                    height: 100%;
                                `}
                            >
                                <CardHeader close={closeFn}>
                                    <div
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            gap: 8px;
                                        `}
                                    >
                                        <Icon width={16} height={16} />
                                        {title}
                                    </div>
                                </CardHeader>
                                <ScrollWrapper
                                    scrollable={scrollable}
                                    onClick={e => e.stopPropagation()}
                                >
                                    <CardBody>{sidePanelBody}</CardBody>
                                </ScrollWrapper>
                            </Card>
                        </div>
                    </div>
                </div>
                {/* </FocusLock> */}
            </motion.div>
        </ModalWrapper>
    )
}

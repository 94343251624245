import { css } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"

// https://nzbin.github.io/three-dots/

const LoadContainer = styled.div`
    justify-content: center;
    display: flex;
`

const LoadParent = styled.div`
    width: 24px;
    justify-content: center;
    align-items: center;
    color: black;
    height: 100%;
    transform: scale(0.8);
    display: flex;
`

const Indicator = styled.div<{ color: string; size: number }>`
    position: relative;
    left: -9999px;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 3px;
    background-color: ${props => props.color};
    color: ${props => props.color};
    box-shadow: 9999px 0 0 0 ${props => props.color};
    animation: dotFalling 1s infinite linear;
    animation-delay: 0.1s;
    /* margin-left: 5px; */

    :before,
    :after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }

    :before {
        width: ${props => props.size}px;
        height: ${props => props.size}px;
        border-radius: 3px;
        background-color: ${props => props.color};
        color: ${props => props.color};
        animation: dotFallingBefore 1s infinite linear;
        animation-delay: 0s;
        left: ${props => props.size / 2}px;
    }

    :after {
        width: ${props => props.size}px;
        height: ${props => props.size}px;
        border-radius: 3px;
        background-color: ${props => props.color};
        color: ${props => props.color};
        animation: dotFallingAfter 1s infinite linear;
        animation-delay: 0.2s;
        left: -${props => props.size / 2}px;
    }

    @keyframes dotFalling {
        0% {
            box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 9999px 0 0 0 ${props => props.color};
        }
        100% {
            box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
        }
    }

    @keyframes dotFallingBefore {
        0% {
            box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 9984px 0 0 0 ${props => props.color};
        }
        100% {
            box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
        }
    }

    @keyframes dotFallingAfter {
        0% {
            box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
        }
        25%,
        50%,
        75% {
            box-shadow: 10014px 0 0 0 ${props => props.color};
        }
        100% {
            box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
        }
    }
`

export function LoadIndicator({
    color = "var(--fg-high)",
    size = 6,
    ...props
}) {
    return (
        <LoadContainer>
            <LoadParent {...props}>
                <Indicator size={size} color={color} />
            </LoadParent>
        </LoadContainer>
    )
}

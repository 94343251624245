import { grayscaleLight, interfaceColors } from "@shiftx/styles"
import React from "react"
import { Trivial, Minor, Major, Critical, Blocker } from "@shiftx/icons"

export const severities = {
    trivial: {
        label: "Trivial",
        color: grayscaleLight[24],
        icon: props => <Trivial {...props} />,
    },
    minor: {
        label: "Minor",
        color: interfaceColors.green.default,
        icon: props => <Minor {...props} />,
    },
    major: {
        label: "Major",
        color: interfaceColors.yellow.default,
        icon: props => <Major {...props} />,
    },
    critical: {
        label: "Critical",
        color: interfaceColors.orange.default,
        icon: props => <Critical {...props} />,
    },
    blocker: {
        label: "Blocker",
        color: interfaceColors.red.default,
        icon: props => <Blocker {...props} />,
    },
}

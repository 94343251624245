import styled from "@emotion/styled"

type SpacerType = {
    width?: string
    height?: string
}

export const Spacer = styled.div<SpacerType>`
    height: ${props => props.height ?? `100%`};
    width: ${props => props.width ?? `100%`};
`

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Spinner } from "@shiftx/components"
import { Plus, X } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"

interface Props {
    children: React.ReactNode
    isRemovable?: boolean
    isLoading?: boolean
    showAddIcon?: boolean
    onClick?: (event: any) => void
    onRemoveItem?: () => void
    paddingRight?: number
}

const TagListItemContainer = styled.div<{ paddingRight: number }>`
    display: flex;
    border-radius: 15px;
    border: 1px solid var(--fg-300);
    ${props => getThemeProp(props.theme, TextStyle.TextXSmall)};
    padding: 0 12px;
    align-items: center;
    height: 30px;
    position: relative;
    padding-right: ${props => props.paddingRight}px;
    cursor: pointer;
    :hover {
        border: 1px solid var(--fg-400);
    }
`

const PlusIcon = styled(Plus)`
    margin-right: 8px;
`

const SpinnerContainer = styled.div`
    margin-left: 8px;
`

const XContainer = styled.div`
    margin-left: 16px;
    padding: 8px 0 8px;
    color: var(--fg-medium);
    :hover {
        color: var(--fg-high);
    }
`

export const TagListItem = ({
    children,
    onClick,
    paddingRight = 12,
    isRemovable,
    isLoading,
    onRemoveItem,
    showAddIcon = false,
    ...props
}: Props) => {
    return (
        <TagListItemContainer
            onClick={event => {
                onClick && onClick(event)
            }}
            paddingRight={paddingRight}
            {...props}
        >
            {showAddIcon && <PlusIcon width="12" height="12" />}

            {children}

            {isLoading && (
                <SpinnerContainer>
                    <Spinner color="#000" size={12} />
                </SpinnerContainer>
            )}

            {isRemovable && (
                <XContainer
                    onClick={event => {
                        event.stopPropagation()
                        onRemoveItem()
                    }}
                >
                    <X width={8} height={8} />
                </XContainer>
            )}
        </TagListItemContainer>
    )
}

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Warning } from "@shiftx/icons/"
import { getThemeProp, interfaceColors, TextStyle } from "@shiftx/styles"
import React from "react"

const WarningBoxChild = styled.div`
    display: flex;
    background: ${interfaceColors.red.default}16;
    ${getThemeProp(props => props.theme, TextStyle.TextSmall)}
    text-align: center;
    border-radius: 4px;
    padding: 16px;
    color: ${interfaceColors.red.default};
    align-items: center;
    flex-direction: column;
`

export const WarningBox = ({ showIcon = true, children, ...props }) => {
    return (
        <WarningBoxChild {...props}>
            {showIcon && (
                <Warning
                    css={css`
                        margin-bottom: 8px;
                    `}
                />
            )}
            {children}
        </WarningBoxChild>
    )
}

import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
import { SeverityType } from "../SearchList/Components/SeverityListItem"

interface Props {
    className?: string
    severity?: SeverityType
}

const Container = styled.div`
    display: flex;
    align-items: center;
`

const Label = styled.div`
    padding: 0 8px;
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
`

export const SeverityBadge = ({ severity }: Props) => {
    const Icon = severity.icon

    return (
        <Container>
            <Icon width={16} height={16} />
            <Label>{severity.label}</Label>
        </Container>
    )
}

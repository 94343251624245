import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"

const HoverColor = styled.div<{ size: number; color: string }>`
    display: flex;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    border: 2px solid ${props => props.color};
    &:hover {
        border: 2px solid #000;

        & > div {
            color: #000;
        }
    }
`

const AvatarContainer = styled.div<{ size: number; color: string }>`
    color: ${props => props.color};
    font-weight: 600;
    font-size: ${props => props.size / 2}px;
    line-height: 28px;
`

const ImageAvatar = styled.div<{ size: number; logoUrl: string }>`
    display: flex;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    background: URL(${props =>
        props.logoUrl}?w=96&blend=fff&bm=difference&pad=10);
    background-size: contain;
`

const GeneratedAvatar = ({ organization, size, ...rest }) => {
    if (!organization?.name) {
        return null
    }
    const { notationColors } = useTheme()
    const colorNames = Object.keys(notationColors)
    const colorIndex =
        organization.name
            .split("")
            .map(i => i.charCodeAt(0))
            .reduce((acc, val) => acc + val) % colorNames.length
    const color = notationColors[colorNames[colorIndex]]

    return (
        <HoverColor color={color.default} size={size} {...rest}>
            <AvatarContainer size={size} color={color.default}>
                {organization.name.charAt(0)}
            </AvatarContainer>
        </HoverColor>
    )
}

export function OrganizationAvatar({ organization, size = 48, ...rest }) {
    if (organization?.logoSymbolUrl) {
        return <ImageAvatar logoUrl={organization.logoSymbolUrl} size={size} />
    } else {
        return (
            <GeneratedAvatar
                organization={organization}
                size={size}
                {...rest}
            />
        )
    }
}

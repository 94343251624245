import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"

interface Props {
    label?: string
    children?: React.ReactNode
    isHighlighted?: boolean
    showPadding?: boolean
    onHover?: () => void
    scrollListRef?: any
}

const Container = styled.div<{
    isHighlighted?: boolean
    showPadding?: boolean
}>`
    ${props => props.showPadding && `padding: 8px 12px;`}
    display: flex;
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    color: ${props =>
        props.isHighlighted ? `var(--fg-high)` : `var(--fg-medium)`};

    background-color: ${props =>
        props.isHighlighted ? `var(--fg-100)` : "transparent"};
    border-radius: 0px;
    cursor: pointer;
    align-items: center;
`

export const SearchListItemContainer = ({
    label,
    children,
    isHighlighted,
    onHover,
    showPadding = false,
    ...props
}: Props) => {
    return (
        <Container
            isHighlighted={isHighlighted}
            showPadding={showPadding}
            {...props}
        >
            {children ? children : label}
        </Container>
    )
}

import { css } from "@emotion/react"
import Link from "next/link"
import React from "react"
import { Image } from "../../components/Image"
import { Title4 } from "../../components/Title"

export const CustomerCasePreview = ({ content }) => {
    return (
        <Link href={`/customers/${content.slug}`} passHref>
            <a
                css={css`
                    display: block;
                    :hover .casePicture img {
                        transform: scale(1.01);
                    }
                `}
            >
                <article>
                    <div
                        css={css`
                            width: 100%;
                            border-radius: 8px;
                            overflow: hidden;
                            position: relative;
                        `}
                        className="casePicture"
                    >
                        {content.headerImage && (
                            <Image
                                image={content.headerImage}
                                width={800}
                                height={500}
                                css={css`
                                    transition: transform 0.2s;
                                `}
                            />
                        )}
                    </div>

                    <Title4
                        css={css`
                            margin-top: 24px;
                            margin-bottom: 16px;
                        `}
                    >
                        {content.title}
                    </Title4>

                    <span
                        css={css`
                            color: var(--fg-high);
                            :hover {
                                color: var(--brand-base);
                            }
                        `}
                    >
                        Read use case {"->"}
                    </span>
                </article>
            </a>
        </Link>
    )
}

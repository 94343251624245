import Hashids from "hashids"
import {
    analyticsDevKey,
    analyticsProdKey,
    printToConsole,
    shouldTrackToAnalytics,
} from "./config"

const hashids = new Hashids("ShiftX")
declare const window: any

export const decodeHash = hash => {
    let id = hash
    try {
        id = hashids.decode(hash)[0].toString()
    } catch (error) {}

    return id
}

const isRunningClient = () => {
    return !!(
        typeof window !== "undefined" &&
        window.document &&
        window.document.createElement
    )
}

export const log = (
    message: string | number,
    props = {},
    props2 = {},
    type
) => {
    if (printToConsole) {
        console[type](message, props, props2)
    }
}

export const isProd = () => {
    return isRunningClient() && window?.location?.host === "shiftx.com"
}

export const getAnalyticsKey = () => {
    if (shouldTrackToAnalytics) {
        if (isProd()) {
            return analyticsProdKey
        } else {
            return analyticsDevKey
        }
    }
}

export const getUrlParams = <G>(string: string): G => {
    const params = new URLSearchParams(string)
    return ([...params.entries()] as any).reduce((object, [key, value]) => {
        object[key] = value
        return object
    }, {})
}

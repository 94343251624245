import styled from "@emotion/styled"
import React from "react"

const Flex = styled.div`
    display: flex;
`
const Content = styled.div`
    flex: 2;
    border-right: 1px solid var(--fg-200);
    overflow-x: hidden;
    min-height: 100vh;
    max-width: 720px;
`

const Panel = styled.div`
    overflow-x: hidden;
    min-width: 320px;
    flex: 1;
    background-color: var(--fg-100);
`

export const PanelPage = ({
    children,
}: {
    children: Array<React.ReactElement>
}) => {
    if (children.length > 2) {
        console.error("Panelpage only accept two children")
    }

    const [content, panel] = children

    return (
        <Flex>
            <Content>{content}</Content>
            <Panel>{panel}</Panel>
        </Flex>
    )
}

const colors = {
    bgPrimary: "#FFFFFF",
    bgSecondary: "#F5F5F5",
    bgElevated: "#FFFFFF",
    brandLow: "#E8EEFD",
    brandBase: "#4040F0",
    brandHigh: "#3143B6",
    onBrand: "#FFFFFF",
    fg100: "rgba(0, 0, 0, 0.03)",
    fg200: "rgba(0, 0, 0, 0.06)",
    fg300: "rgba(0, 0, 0, 0.12)",
    fg400: "rgba(0, 0, 0, 0.24)",
    fg400solid: "#c2c2c2",
    fgLow: "rgba(0, 0, 0, 0.38)",
    fgMedium: "rgba(0, 0, 0, 0.6)",
    fgHigh: "rgba(0, 0, 0, 0.87)",
    fgColor: "#FFFFFF",
    red: {
        low: "#FDE9EB",
        base: "#F04858",
        high: "#B63743",
    },
    orange: { low: "#FEEEE4", base: "#FC7124", high: "#C0561B" },
    yellow: { low: "#FEF5E3", base: "#FAAD18", high: "#BE8312" },
    green: { low: "#E6F5EC", base: "#2FB264", high: "#24874C" },
    blue: { low: "#E8EFFC", base: "#467EEB", high: "#3560B3" },
    purple: { low: "#F4E9F9", base: "#A84BD4", high: "#8039A1" },
}

export const notationColors = {
    red: {
        lightest: "#F4BDB5",
        lighter: "#F9DEDA",
        default: "#DC3018",
        darker: "#B92814",
        darkest: "#962110",
    },
    yellow: {
        lighter: "#FEEDDA",
        lightest: "#FDDAB5",
        default: "#F88C18",
        darker: "#D07614",
        darkest: "#A95F10",
    },
    green: {
        lighter: "#E3F0DD",
        lightest: "#C7E2BA",
        default: "#50A428",
        darker: "#438A22",
        darkest: "#36701B",
    },
    turquoise: {
        lighter: "#DAF0F3",
        lightest: "#B5E2E7",
        default: "#18A4B4",
        darker: "#148A97",
        darkest: "#10707A",
    },
    blue: {
        lighter: "#DAE3F6",
        lightest: "#B5C7ED",
        default: "#1850C8",
        darker: "#1443A8",
        darkest: "#103688",
    },
    purple: {
        lighter: "#E6DDF0",
        lightest: "#CCBAE2",
        default: "#6028A4",
        darker: "#51228A",
        darkest: "#411B70",
    },
    pink: {
        lighter: "#F6DDF0",
        lightest: "#EDBAE2",
        default: "#C828A4",
        darker: "#A8228A",
        darkest: "#881B70",
    },
    gray: {
        default: "#787878",
    },
    brown: {
        default: "#604030",
    },
}

export const grayscaleLight = {
    100: "#181822",
    80: "#3F3F50",
    64: "#484A5B",
    48: "#6C7489",
    32: "#95A0B2",
    24: "#C3CBD5",
    16: "#D9E1E8",
    12: "#E6EBEF",
    8: "#F2F5F7", //
    4: "#F8F9FB", //
    2: "#FCFCFD",
    0: "#FFFFFF", //
}

const textXLarge = {
    fontSize: "20px",
    lineHeight: "32px",
    fontWeight: 700,
}

const textLarge = {
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: 600,
}

const textMedium = {
    fontSize: "13px",
    lineHeight: "24px",
    fontWeight: 600,
}

const textSmall = {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 500,
}

const textXSmall = {
    fontSize: "11px",
    lineHeight: "16px",
    fontWeight: 500,
}

const link = {
    cursor: "pointer",
}

const ellipsis = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: 240,
    whiteSpace: "nowrap",
}

export const interfaceColors = {
    red: {
        lightest: "#FDE2E4",
        lighter: "#FAC4CA",
        default: "#F04858",
        darker: "#CA3C4A",
        darkest: "#A3313C",
    },
    orange: {
        lightest: "#FDE9DF",
        lighter: "#FCD3BF",
        default: "#F57438",
        darker: "#CE612F",
        darkeste: "#A74F26",
    },
    yellow: {
        lightest: "#FFF5E8",
        lighter: "#FEF0DA",
        default: "#FAA018",
        darker: "#D28614",
        darkest: "#AA6D10",
    },
    oliveGreen: {
        lightest: "#EDF3E2",
        lighter: "#DCE6C5",
        default: "#91B149",
        darker: "#7A953D",
        darkest: "#637832",
    },
    green: {
        lightest: "#BBEBD4",
        lighter: "#DDF5EA",
        default: "#29C279",
        darker: "#22A366",
        darkest: "#1C8452",
    },
    blue: {
        lightest: "#EDF2FD",
        lighter: "#E2EBFD",
        default: "#4881F0",
        darker: "#3C6CCA",
        darkest: "#3158A3",
    },
    gray: {
        default: "#A0A0A0",
    },
}

export const lightTheme = {
    name: "light",
    colors,
    gray: grayscaleLight,
    textXSmall,
    textSmall,
    textMedium,
    textLarge,
    textXLarge,
    link,
    interfaceColors,
    notationColors,
    ellipsis,
}

export type Colors = typeof colors

import styled from "@emotion/styled"
import { X } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
import { UserAvatar } from "../../UserAvatar/UserAvatar"
import { fullName } from "../../utils"

export interface User {
    id: string
    displayLabel?: string
    firstName?: string
    lastName?: string
    displayName: string
    fullName?: string
    email: string
    createdAt?: string
    picture?: string
    role?: RoleNames
    membershipId?: string
    hash?: string
    membership?: {
        role: RoleNames
    }
}

export enum RoleNames {
    PrimaryOwner = "primaryOwner",
    Owner = "owner",
    Administrator = "administrator",
    User = "user",
    Contributor = "contributor",
    Observer = "observer",
    Blocked = "blocked",
}

export interface UserListPropsType {
    user: any
    onRemoveItem?: () => void
    hidePadding?: boolean
}

const Container = styled.div<{ hidePadding?: boolean }>`
    display: flex;
    align-items: center;
    flex: 1;
    ${props => !props.hidePadding && `padding: 8px 12px;`}
`

const Text = styled.div`
    margin-left: 8px;
    ${props => getThemeProp(props.theme, TextStyle.Ellipsis)};
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
`

const RemoveIcon = styled(X)`
    margin-left: auto;
`

export const UserListItem = ({
    onRemoveItem,
    user,
    hidePadding = false,
    ...props
}: UserListPropsType) => {
    const name = user.displayLabel ? user.displayLabel : fullName(user)
    return (
        <Container
            data-testid={`user-list-item-${name}`}
            hidePadding={hidePadding}
        >
            <UserAvatar user={user} size={16} />
            <Text {...props}>{name}</Text>

            {Boolean(onRemoveItem) && (
                <RemoveIcon
                    width={12}
                    height={12}
                    onClick={event => {
                        event.stopPropagation()
                        onRemoveItem()
                    }}
                />
            )}
        </Container>
    )
}

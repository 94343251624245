import styled from "@emotion/styled"
import { Plus } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
export interface TextListItemType {
    id: string
    label: string
    description?: string
}
interface TextListItemProps {
    children: React.ReactNode
    showActionIcon?: boolean
    hidePadding?: boolean
    item: TextListItemType
}

const TextListItemContainer = styled.div<{ hidePadding?: boolean }>`
    flex: 1;
    display: flex;
    align-items: center;
    ${props => !props.hidePadding && `padding: 8px 12px;`}
`

const TextContainer = styled.div<{ showActionIcon: boolean }>`
    margin: 0 ${props => (props.showActionIcon ? "8px" : "0px")};
    color: var(--fg-high);
`

const DescriptionContainer = styled.div`
    margin-top: 4px;
    ${props => getThemeProp(props.theme, TextStyle.TextXSmall)}
    color: var(--fg-medium);
`

export const TextListItem = ({
    item,
    children,
    showActionIcon,
    ...props
}: TextListItemProps) => {
    return (
        <div>
            <TextListItemContainer {...props}>
                {showActionIcon && <Plus width={16} height={16} />}
                <TextContainer showActionIcon={showActionIcon}>
                    {children}
                </TextContainer>
            </TextListItemContainer>
            {item?.description && (
                <DescriptionContainer>{item?.description}</DescriptionContainer>
            )}
        </div>
    )
}

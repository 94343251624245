import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
import { actorCategories } from "../../../data/actorCategories"

const Container = styled.div<{ hidePadding?: boolean }>`
    display: flex;
    align-items: center;
    ${props => !props.hidePadding && `padding: 8px 12px;`}
`

const Color = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: ${props => props.color};
`

const Name = styled.div`
    margin-left: 8px;
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
`

export interface ActorCategoryListItemProps {
    actorCategoryId: string
    hidePadding?: boolean
}

export const ActorCategoryListItem = ({
    actorCategoryId,
    hidePadding = false,
}: ActorCategoryListItemProps) => {
    const selected = actorCategories.find(c => c.id === actorCategoryId)
    return (
        <Container hidePadding={hidePadding}>
            <Color color={selected?.color}></Color>
            <Name>{selected?.name}</Name>
        </Container>
    )
}

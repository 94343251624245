import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React, { forwardRef } from "react"

const InfoBoxContainer = styled.div`
    background-color: var(--fg-100);
    ${getThemeProp(props => props.theme, TextStyle.TextSmall)}
    text-align: center;
    border-radius: 4px;
    padding: 16px;
`

interface Props {
    children: React.ReactNode
}

export const InfoBox = forwardRef<HTMLDivElement, Props>(
    ({ children, ...props }, ref) => {
        return (
            <InfoBoxContainer {...props} ref={ref}>
                {children}
            </InfoBoxContainer>
        )
    }
)

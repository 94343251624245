import styled from "@emotion/styled"
import { X } from "@shiftx/icons"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React, { InputHTMLAttributes } from "react"

export const InputBase = React.forwardRef<
    HTMLInputElement,
    InputHTMLAttributes<HTMLInputElement>
>((props, ref) => {
    return <InputBaseStyle autoComplete="off" ref={ref} {...props} />
})

interface Input extends InputHTMLAttributes<HTMLInputElement> {
    error?: string
}

export const InputBaseStyle = styled.input<
    InputHTMLAttributes<HTMLInputElement>
>`
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    padding: 8px 12px;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    background-color: var(--bg-elevated);
    height: 36px;
    min-width: 160px;
    color: var(--fg-high);

    ::placeholder {
        ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
        color: var(--fg-low);
    }

    &:focus {
        outline: none;
        ::placeholder {
            color: var(--fg-low);
            opacity: 0.5;
        }
    }
`

export const Input = React.forwardRef<HTMLInputElement, Input>((props, ref) => {
    return <InputStyle autoComplete="off" ref={ref} {...props} />
})

export const InputStyle = styled.input<{
    error?: string
}>`
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    border: 1px solid
        ${props => (props.error ? `var(--interface-red)` : `var(--fg-300)`)};

    background-color: var(--bg-elevated);
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    box-sizing: border-box;
    -webkit-appearance: none;
    height: 36px;
    min-width: 160px;
    color: var(--fg-high);

    &:hover {
        border: 1px solid;
        border-color: ${props =>
            props.error ? `var(--interface-red)` : `var(--fg-400)`};
    }

    &:focus {
        outline: none;
        border: 1px solid;

        border-color: ${props =>
            props.error ? `var(--interface-red)` : `var(--brand-base)`};

        ::placeholder {
            color: var(--fg-low);
            opacity: 0.5;
        }
    }

    ::placeholder {
        ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
        color: var(--fg-low);
    }

    :disabled {
        background-color: var(--fg-200);
        border-color: var(--fg-300);
        color: var(--fg-low);
    }
`

const XContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    position: relative;
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    onClear: () => void
    value?: string
}

export const InputWithClear = React.forwardRef<HTMLInputElement, Props>(
    ({ onClear, ...props }, ref) => {
        return (
            <Container>
                <Input ref={ref} {...props} />

                {props.value && (
                    <XContainer
                        onClick={() => {
                            onClear && onClear()
                        }}
                        onMouseDown={event => event.preventDefault()}
                    >
                        <X width={8} height={8} />
                    </XContainer>
                )}
            </Container>
        )
    }
)

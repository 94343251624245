import { isRunningClient } from "./isRunningClient"

export const isProd = () => {
    return isRunningClient() && window.location.host === "shiftx.com"
}

export const isLocalhost = () => {
    return isRunningClient() && window.location.host.includes("localhost")
}

export const isDev = () => {
    return isRunningClient() && window.location.host === "dev.shiftx.com"
}

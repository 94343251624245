import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
import { Hint } from "../Hint/Hint"
import { createAvatarString, displayName } from "../utils"

const AvatarContainer = styled.div<{
    size: number
    picture: string
}>`
    background-color: #121212;
    background-image: ${props =>
        props.picture ? `url(${props => props.picture})` : null};
    background-size: contain;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: ${props => props.size / 2}px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    display: flex;
    transition: all 0.1s ease-in-out;
`

const Avatar = styled.div<{ size: number }>`
    text-align: center;
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    color: #fff;
    font-weight: 700;
    font-size: ${props => props.size / 2}px;
`

interface Props {
    user?: any
    size?: number
    showHint?: boolean
    hintText?: string
}

export const UserAvatar = ({
    user,
    size = 24,
    showHint = false,
    hintText,
    ...rest
}: Props) => {
    let picture = null
    let avatar = ""

    if (user) {
        const { email, firstName, lastName } = user
        picture = user.picture
        avatar = createAvatarString(email, firstName, lastName)
    }

    const AvatarContainerRender = (
        <AvatarContainer size={size} picture={picture} {...rest}>
            {!picture && <Avatar size={size}>{avatar}</Avatar>}
        </AvatarContainer>
    )

    if (showHint) {
        return (
            <Hint helpText={hintText ?? displayName(user)}>
                {AvatarContainerRender}
            </Hint>
        )
    } else {
        return <div>{AvatarContainerRender}</div>
    }
}

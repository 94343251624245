import { gray } from "./colors"

export enum TextStyle {
    TextLarge = "textLarge",
    TextMedium = "textMedium",
    TextSmall = "textSmall",
    TextXSmall = "textXSmall",
    Link = "link",
    Ellipsis = "ellipsis",
}

export const colors = {
    background: {
        body: "#FFFFFF",
        panel: "#FFFFFF",
        surface: "#FFFFFF",
        inverted: "#121212",
        canvas: "#F5F5F5",
        brand: "#4040F0",
        brand200: "#3838D4",
        surface100: "#F7F7F7",
        surface200: "#F0F0F0",
        100: "rgba(0, 0, 0, 0.03)",
        200: "rgba(0, 0, 0, 0.06)",
        300: "rgba(0, 0, 0, 0.12)",
        400: "rgba(0, 0, 0, 0.24)",
    },
    font: {
        high: "rgba(0, 0, 0, 0.87)",
        medium: "rgba(0, 0, 0, 0.6)",
        low: "rgba(0, 0, 0, 0.38)",
        inverted: "#FFFFFF",
        brand: "#4040F0",
    },
    border: {
        100: "rgba(0, 0, 0, 0.03)",
        200: "rgba(0, 0, 0, 0.06)",
        300: "rgba(0, 0, 0, 0.12)",
        400: "rgba(0, 0, 0, 0.24)",
        brand: "#4040F0",
    },
    onBackground: {
        high: "rgba(0, 0, 0, 0.87)",
        medium: "rgba(0, 0, 0, 0.6)",
        low: "rgba(0, 0, 0, 0.38)",
        inverted: "#FFFFFF",
    },
    neutral: {
        100: "rgba(0, 0, 0, 0.03)",
        200: "rgba(0, 0, 0, 0.06)",
        300: "rgba(0, 0, 0, 0.12)",
        400: "rgba(0, 0, 0, 0.24)",
    },

    brand: {
        primary: "#4040F0",
    },
    onBrand: { primary: "#FFFFFF" },
}

const textLarge = {
    fontSize: "15px",
    lineHeight: "24px",
    color: colors.onBackground.high,
    fontWeight: 600,
}

const textMedium = {
    fontSize: "13px",
    lineHeight: "24px",
    color: colors.onBackground.high,
    fontWeight: 600,
}

const textSmall = {
    fontSize: "12px",
    lineHeight: "20px",
    color: colors.onBackground.high,
    fontWeight: 500,
}

const textXSmall = {
    fontSize: "11px",
    lineHeight: "16px",
    color: colors.onBackground.high,
    fontWeight: 500,
}

const link = {
    cursor: "pointer",
}

const ellipsis = {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: 240,
    whiteSpace: "nowrap",
}

export const publicTheme = {
    name: "publicTheme",
    gray,
    textSmall,
    textMedium,
    textLarge,
    textXSmall,
    link,
    ellipsis,
    colors,
}

import * as React from "react"

function SvgBlocked(props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.02 2.047a10.108 10.108 0 011.96 0l-.194 1.991a8.11 8.11 0 00-1.572 0l-.193-1.99zM7.286 3.18a9.945 9.945 0 011.812-.752l.58 1.914c-.506.153-.99.355-1.448.6L7.285 3.18zm7.618-.752c.633.192 1.24.445 1.812.752l-.944 1.763a7.944 7.944 0 00-1.448-.6l.58-1.915zM4.27 5.656c.416-.506.88-.97 1.386-1.386l1.27 1.545a8.055 8.055 0 00-1.11 1.11L4.27 5.655zM18.345 4.27c.505.416.97.88 1.385 1.386l-1.545 1.27a8.058 8.058 0 00-1.11-1.11l1.27-1.546zM2.428 9.097c.192-.633.445-1.24.752-1.812l1.763.944a7.945 7.945 0 00-.6 1.448l-1.915-.58zM20.82 7.285c.307.572.56 1.179.752 1.812l-1.914.58a7.947 7.947 0 00-.6-1.448l1.762-.944zM2 12c0-.33.016-.657.047-.98l1.991.194a8.11 8.11 0 000 1.572l-1.99.193C2.015 12.657 2 12.33 2 12zm19.953-.98a10.105 10.105 0 010 1.96l-1.991-.194a8.117 8.117 0 000-1.572l1.99-.193zM3.18 16.716a9.946 9.946 0 01-.752-1.812l1.914-.58c.153.506.355.99.6 1.448l-1.762.944zm18.392-1.812a9.945 9.945 0 01-.752 1.812l-1.763-.944c.246-.458.448-.942.6-1.448l1.915.58zM5.656 19.73c-.506-.416-.97-.88-1.386-1.386l1.545-1.27c.333.406.705.778 1.11 1.11l-1.27 1.546zm14.074-1.386c-.416.506-.88.97-1.386 1.386l-1.27-1.545a8.062 8.062 0 001.11-1.11l1.546 1.27zM9.097 21.573a9.945 9.945 0 01-1.812-.752l.944-1.763c.458.246.942.448 1.448.6l-.58 1.915zm7.618-.752c-.572.307-1.179.56-1.812.752l-.58-1.914c.506-.154.99-.355 1.448-.6l.944 1.762zM12 22c-.33 0-.657-.016-.98-.047l.194-1.991a8.117 8.117 0 001.572 0l.193 1.99c-.322.032-.649.048-.979.048z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgBlocked

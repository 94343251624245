import { css } from "@emotion/react"
import styled from "@emotion/styled"
import React from "react"
import { createContext } from "../utils/createContext"

const StyledCard = styled.div<{ shadow?: boolean }>`
    display: flex;
    background-color: var(--bg-elevated);
    border: 1px solid var(--fg-200);
    border-radius: 6px;
    flex-direction: column;

    ${props =>
        props.shadow &&
        css`
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
            border: 0;
        `}

    ${props =>
        props.onClick &&
        css`
            cursor: pointer;
            :hover {
                border: 1px solid var(--fg-300);
            }
        `}
`

export const [CardProvider, useCardContext] = createContext("Card")

export function Card({ children, ...rest }) {
    return (
        <CardProvider>
            <StyledCard {...rest}>{children}</StyledCard>
        </CardProvider>
    )
}

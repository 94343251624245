import styled from "@emotion/styled"
import { Check } from "@shiftx/icons"
import { getGray } from "@shiftx/styles"
import React from "react"

const themeColorPicker = (color, fallback = null) => {
    const array = color.split(".")
    return ({ theme }) => {
        return array.reduce((acc, key) => {
            return acc && acc[key] ? acc[key] : fallback
        }, theme.colors)
    }
}

const HiddenCheckbox = styled.input`
    // Hide checkbox visually but remain accessible to screen readers.
    // Source: https://polished.js.org/docs/#hidevisually
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

const StyledCheckbox = styled.div<{ checked: boolean }>`
    /* display: inline-block; */
    width: 16px;
    height: 16px;
    padding: 2px;
    box-sizing: border-box;
    background-color: ${props =>
        props.checked ? `var(--brand-base)` : "none"};
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    border-color: ${props =>
        props.checked ? `var(--brand-base)` : getGray(24)};
    cursor: pointer;
    color: #fff;

    :hover {
        background: ${props =>
            props.checked
                ? themeColorPicker("primary.default")(props)
                : "inherit"};
    }
    .hiddenCheckBox:focus + &,
    :hover {
        border-color: ${props =>
            props.checked ? `var(--brand-base)` : getGray(32)};
    }
`

const CheckboxContainer = styled.div`
    vertical-align: middle;
`

export const Checkbox = ({ value, onChange, ...props }) => {
    return (
        <CheckboxContainer>
            <HiddenCheckbox
                type="checkbox"
                className="hiddenCheckBox"
                checked={value}
                onChange={onChange}
                {...props}
            />
            <StyledCheckbox checked={value} onClick={onChange}>
                {value && <Check width="10" height="10" />}
            </StyledCheckbox>
        </CheckboxContainer>
    )
}

import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"
import { Popper } from "../Popper/Popper"
import { DefaultListItem } from "./MenuListItem"

const Items = styled.div<{ scrollheight: string; showShadow: boolean }>`
    overflow-y: scroll;
    max-height: ${props => props.scrollheight}px;
    min-width: 224px;
    background: #fff;
    border-radius: 4px;
    padding: 4px 0px;

    ${props =>
        props.showShadow &&
        `box-shadow: 0px 4px 40px rgba(24, 25, 32, 0.1);width:100%;`}
`

const MenuSeperator = styled.div`
    height: 1px;
    background: var(--fg-300);
    margin: 4px 0px;
`

interface ItemCompProps<Item> {
    item: Item
    onHover: () => void
    isHighlighted: boolean
    isSelectedMode: boolean
    scrollListRef: any
}

export interface MenuListProps<Item> {
    items: Array<DefaultListItem<Item>>
    initialSelectedItemId?: string
    onSelectItem?: ({
        item,
        parentItem,
        event,
    }: {
        item: DefaultListItem<Item>
        parentItem?: any
        event?: React.MouseEvent
    }) => void
    itemComp?: ({
        item,
        onHover,
        isHighlighted,
        isSelectedMode,
        scrollListRef,
    }: ItemCompProps<DefaultListItem<Item>>) => React.ReactNode
    showShadow?: boolean
    useItemAction?: boolean
    enableScroll?: boolean
}

export const MenuList = <Item extends any>({
    items,
    initialSelectedItemId = null,
    onSelectItem = null,
    itemComp,
    showShadow = false,
    useItemAction = true,
    enableScroll = true,
}: MenuListProps<Item>) => {
    const initialSelectedItemIndex = initialSelectedItemId
        ? items.findIndex(item => item.id === initialSelectedItemId)
        : null
    useEffect(() => {
        if (initialSelectedItemId) {
            scrollToItem(initialSelectedItemId)
        }
    }, [])

    const internalItems = items
        .filter(item => {
            return item.hide !== true
        })
        .filter(item => {
            return !item?.disabled?.()
        })

    const [highlightedIndex, setHighlightedIndex] = useState(
        initialSelectedItemIndex
    )

    const [subHighlightedIndex, setSubHighlightedIndex] = useState(-1)

    useEffect(() => {
        if (!initialSelectedItemId) {
            setHighlightedIndex(0)
        }
    }, [initialSelectedItemId, internalItems.length])

    const scrollToItem = itemId => {
        const itemRef = itemsRef?.current?.[itemId]
        if (itemRef) {
            scrollListRef?.current?.scroll?.(
                0,
                itemRef.offsetTop - itemRef.offsetHeight - 10
            )
        }
    }

    const navigateList = (key, event) => {
        const parentItem = internalItems.find((item, index) => {
            return index === highlightedIndex
        })

        const hasChildMenu = parentItem?.subMenu

        if (subHighlightedIndex < 0) {
            if (key === "ArrowUp") {
                const prevItem = internalItems.find((item, index) => {
                    return index === highlightedIndex - 1
                })

                scrollToItem(prevItem?.id)

                if (highlightedIndex === null) {
                    setHighlightedIndex(highlightedIndex)
                } else if (highlightedIndex === 0) {
                    setHighlightedIndex(internalItems.length - 1)
                } else {
                    setHighlightedIndex(
                        highlightedIndex - 1 - (prevItem.seperator ? 1 : 0)
                    )
                }
            }

            if (key === "ArrowDown") {
                const nextItem = internalItems.find((item, index) => {
                    return index === highlightedIndex + 1
                })

                scrollToItem(nextItem?.id)

                if (highlightedIndex === null) {
                    setHighlightedIndex(0)
                } else if (highlightedIndex < internalItems.length - 1) {
                    setHighlightedIndex(
                        highlightedIndex + 1 + (nextItem.seperator ? 1 : 0)
                    )
                } else {
                    setHighlightedIndex(0)
                }
            }

            if (key === "Enter" || key === "Tab") {
                if (highlightedIndex !== null && internalItems.length > 0) {
                    const item = internalItems[highlightedIndex]

                    useItemAction &&
                        item?.onSelectItem &&
                        item.onSelectItem?.({
                            item,
                            event,
                        })

                    onSelectItem && onSelectItem({ item })
                }
            }

            if (key === "ArrowRight" && hasChildMenu) {
                setHover(highlightedIndex)
                setSubHighlightedIndex(0)
            }
        }

        if (hasChildMenu && subHighlightedIndex >= 0) {
            const childMenuLength = parentItem.subMenu.length - 1

            const subItem = internalItems.find((item, index) => {
                return index === subHighlightedIndex
            })

            if (key === "ArrowLeft") {
                setHover(null)
                setSubHighlightedIndex(-1)
            }

            if (key === "ArrowUp") {
                if (subHighlightedIndex === null) {
                    setSubHighlightedIndex(subHighlightedIndex)
                } else if (subHighlightedIndex === 0) {
                    setSubHighlightedIndex(childMenuLength)
                } else {
                    setSubHighlightedIndex(subHighlightedIndex - 1)
                }
            }

            if (key === "ArrowDown") {
                if (subHighlightedIndex === null) {
                    setSubHighlightedIndex(0)
                } else if (subHighlightedIndex < childMenuLength) {
                    setSubHighlightedIndex(subHighlightedIndex + 1)
                } else {
                    setSubHighlightedIndex(0)
                }
            }
        }
    }
    const scrollListRef = useRef(null)

    const onKeyDown = e => {
        e.stopPropagation()
        if (
            e.key === "ArrowUp" ||
            e.key === "ArrowDown" ||
            e.key === "Enter" ||
            e.key === "Tab" ||
            e.key === "ArrowRight" ||
            e.key === "ArrowLeft"
        ) {
            e.preventDefault()
            navigateList(e.key, e)
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown)
        return () => {
            document.removeEventListener("keydown", onKeyDown)
        }
    }, [internalItems, highlightedIndex, subHighlightedIndex])

    const [hover, setHover] = useState(null)
    const [subMenuRef, setSubMenuRef] = useState<HTMLDivElement>()
    const hoverItem = internalItems.find((item, index) => index === hover)
    const isSelectedMode = internalItems.some(item =>
        item.hasOwnProperty("selected")
    )

    const isSubMenuSelectedMode = hoverItem?.subMenu?.some(item =>
        item.hasOwnProperty("selected")
    )

    const itemsRef = useRef([])

    return (
        <Items
            ref={scrollListRef}
            scrollheight={enableScroll ? "200" : "100%"}
            showShadow={showShadow}
        >
            {internalItems.map((item, index) => {
                if (item.seperator) {
                    return <MenuSeperator key={`seperator-${index}`} />
                }
                const itemIndex = internalItems.findIndex(
                    currentItem => currentItem.id === item.id
                )
                const isHighlighted = highlightedIndex === itemIndex

                const onHover = () => {
                    setHighlightedIndex(itemIndex)
                }

                return (
                    <div
                        onPointerMove={() => {
                            onHover && onHover()
                        }}
                        key={`${item.id}-${index}`}
                        onClick={async event => {
                            useItemAction &&
                                item?.onSelectItem &&
                                (await item?.onSelectItem?.({
                                    item,
                                    event,
                                }))

                            onSelectItem &&
                                (await onSelectItem({
                                    item,
                                    event,
                                }))
                        }}
                        onPointerEnter={() => {
                            setHover && setHover(index)
                        }}
                        ref={el => {
                            itemsRef.current[item.id] = el

                            if (hover === index) {
                                setSubMenuRef(el)
                            }
                        }}
                    >
                        {itemComp({
                            item,
                            onHover,
                            isHighlighted,
                            isSelectedMode,
                            scrollListRef,
                        })}
                    </div>
                )
            })}

            {subMenuRef && hoverItem?.subMenu && (
                <Popper
                    alwaysOpen={true}
                    blocked={false}
                    reference={subMenuRef}
                    disablePortal={true}
                    options={{
                        placement: "right-start",
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 0],
                                },
                            },
                        ],
                    }}
                >
                    <Items scrollheight={"200"} showShadow={showShadow}>
                        {hoverItem?.subMenu?.map((item, index) => {
                            const itemIndex = hoverItem?.subMenu?.findIndex(
                                tag => tag.id === item.id
                            )
                            const isHighlighted =
                                subHighlightedIndex === itemIndex

                            const onHover = () => {
                                setSubHighlightedIndex(itemIndex)
                            }

                            return (
                                <div
                                    key={`${item.id}-${index}`}
                                    onClick={async event => {
                                        useItemAction &&
                                            item?.onSelectItem &&
                                            (await item?.onSelectItem?.({
                                                item,
                                                parentItem: hoverItem,
                                                event,
                                            }))

                                        onSelectItem &&
                                            (await onSelectItem({
                                                item,
                                                parentItem: hoverItem,
                                                event,
                                            }))
                                    }}
                                    onPointerEnter={() => {
                                        setSubHighlightedIndex &&
                                            setSubHighlightedIndex(index)
                                    }}
                                >
                                    {itemComp({
                                        item,
                                        onHover,
                                        isHighlighted,
                                        isSelectedMode: isSubMenuSelectedMode,
                                        scrollListRef,
                                    })}
                                </div>
                            )
                        })}
                    </Items>
                </Popper>
            )}
        </Items>
    )
}

export const breakpoints = [600, 960, 1280]

export const space = [
    0, // 0 None
    4, // 1 XXSmall
    8, // 2 XSmall
    12, // 3 Small
    16, // 4 Medium
    24, // 5 Large
    32, // 6 XLarge
    40, // 7 XXLarge
    48, // 8 XXXLarge
]

export const fontSizes = [12, 14, 16, 20, 24, 32]

export const primaryColor = {
    regular: "#3C14DC",
    dark: "#14148C",
    light: "#B4B4F0",
    lighter: "#BABAFA",
    lightest: "#DADAFC",
    default: "#4040f0",
    darker: "#3211B9",
    darkest: "#290E96",
}

export const primaryColorDark = {
    regular: "#3C14DC",
    dark: "#14148C",
    light: "#B4B4F0",
    lighter: "#BABAFA",
    lightest: "#DADAFC",
    default: "#a6a6f5",
    darker: "#3211B9",
    darkest: "#290E96",
}

export const grayscaleDark = {
    100: "#FFFFFF",
    80: "#D1D1D2",
    64: "#ACACAF",
    48: "#87878B",
    32: "#626367",
    24: "#4F5056",
    16: "#3D3E44",
    12: "#34353B",
    8: "#2A2B32",
    4: "#212229",
    2: "#1D1E24",
    0: "#181920",
}

export const grayscaleLight = {
    100: "#181822",
    80: "#3F3F50",
    64: "#484A5B",
    48: "#6C7489",
    32: "#95A0B2",
    24: "#C3CBD5",
    16: "#D9E1E8",
    12: "#E6EBEF",
    8: "#F2F5F7", //
    4: "#F8F9FB", //
    2: "#FCFCFD",
    0: "#FFFFFF", //
}

export const grayscaleDarkMode = {
    100: grayscaleDark[100],
    80: grayscaleDark[80],
    64: grayscaleDark[80],
    48: grayscaleDark[48],
    32: grayscaleDark[32],
    24: grayscaleDark[24],
    16: grayscaleDark[16],
    12: grayscaleDark[12],
    8: grayscaleDark[8],
    4: grayscaleDark[0],
    2: grayscaleDark[2],
    0: grayscaleDark[4],
}

export const interfaceColors = {
    red: {
        lightest: "#FDE2E4",
        lighter: "#FAC4CA",
        default: "#F04858",
        darker: "#CA3C4A",
        darkest: "#A3313C",
    },
    orange: {
        lightest: "#FDE9DF",
        lighter: "#FCD3BF",
        default: "#F57438",
        darker: "#CE612F",
        darkeste: "#A74F26",
    },
    yellow: {
        lightest: "#FFF5E8",
        lighter: "#FEF0DA",
        default: "#FAA018",
        darker: "#D28614",
        darkest: "#AA6D10",
    },
    oliveGreen: {
        lightest: "#EDF3E2",
        lighter: "#DCE6C5",
        default: "#91B149",
        darker: "#7A953D",
        darkest: "#637832",
    },
    green: {
        lightest: "#BBEBD4",
        lighter: "#DDF5EA",
        default: "#29C279",
        darker: "#22A366",
        darkest: "#1C8452",
    },
    blue: {
        lightest: "#EDF2FD",
        lighter: "#E2EBFD",
        default: "#4881F0",
        darker: "#3C6CCA",
        darkest: "#3158A3",
    },
    gray: {
        default: "#A0A0A0",
    },
}

export const avatarColors = [
    interfaceColors.red,
    interfaceColors.orange,
    interfaceColors.yellow,
    interfaceColors.blue,
]

interface UserColor {
    primary: string
    secondary: string
}

export interface UserColors {
    red: UserColor
    yellow: UserColor
    green: UserColor
    turquoise: UserColor
    blue: UserColor
    purple: UserColor
    pink: UserColor
}

export const userColors: UserColors = {
    red: { primary: "220, 48, 24", secondary: "150, 33, 16" },
    yellow: { primary: "248, 140, 24", secondary: "169, 95, 16" },
    green: { primary: "80, 164, 40", secondary: "54, 112, 27" },
    turquoise: { primary: "24, 164, 180", secondary: "16, 112, 122" },
    blue: { primary: "24, 80, 200", secondary: "16, 54, 136" },
    purple: { primary: "96, 40, 164", secondary: "16, 54, 136" },
    pink: { primary: "200, 40, 164", secondary: "65, 27, 112" },
}

export const notationColors = {
    red: {
        lightest: "#F4BDB5",
        lighter: "#F9DEDA",
        default: "#DC3018",
        darker: "#B92814",
        darkest: "#962110",
    },
    yellow: {
        lighter: "#FEEDDA",
        lightest: "#FDDAB5",
        default: "#F88C18",
        darker: "#D07614",
        darkest: "#A95F10",
    },
    green: {
        lighter: "#E3F0DD",
        lightest: "#C7E2BA",
        default: "#50A428",
        darker: "#438A22",
        darkest: "#36701B",
    },
    turquoise: {
        lighter: "#DAF0F3",
        lightest: "#B5E2E7",
        default: "#18A4B4",
        darker: "#148A97",
        darkest: "#10707A",
    },
    blue: {
        lighter: "#DAE3F6",
        lightest: "#B5C7ED",
        default: "#1850C8",
        darker: "#1443A8",
        darkest: "#103688",
    },
    purple: {
        lighter: "#E6DDF0",
        lightest: "#CCBAE2",
        default: "#6028A4",
        darker: "#51228A",
        darkest: "#411B70",
    },
    pink: {
        lighter: "#F6DDF0",
        lightest: "#EDBAE2",
        default: "#C828A4",
        darker: "#A8228A",
        darkest: "#881B70",
    },
    gray: {
        default: "#787878",
    },
    brown: {
        default: "#604030",
    },
}

export enum TextStyle {
    TextXLarge = "textXLarge",
    TextLarge = "textLarge",
    TextMedium = "textMedium",
    TextSmall = "textSmall",
    TextXSmall = "textXSmall",
    Link = "link",
    Ellipsis = "ellipsis",
}

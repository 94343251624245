import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
import { Hint } from "../Hint/Hint"

const AvatarContainer = styled.div<{ size: number }>`
    background-color: #121212;
    background-size: contain;
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: ${props => props.size / 2}px;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    display: flex;
    transition: all 0.1s ease-in-out;
`

const Avatar = styled.div<{ size: number }>`
    text-align: center;
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    color: #fff;
    font-weight: 700;
    font-size: ${props => props.size / 2}px;
`

interface Props {
    name?: string
    showHint?: boolean
    size?: number
}

export const ProcessAvatar = ({
    showHint = true,
    size = 24,
    name = "",
    ...rest
}: Props) => {
    const AvatarContainerRender = (
        <AvatarContainer size={size}>
            <Avatar size={size}>{name.substring(0, 1)}</Avatar>
        </AvatarContainer>
    )

    if (showHint) {
        return <Hint helpText={name}>{AvatarContainerRender}</Hint>
    } else {
        return <div>{AvatarContainerRender}</div>
    }
}

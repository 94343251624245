import Head from "next/head"
import React from "react"
import { imageBuilder } from "../../config/sanity"

interface Props {
    title?: string
    description?: string
    ogTitle?: string
    ogImage?: string
    type?: string
    url?: string
    publishedTime?: string
    modifiedTime?: string
    section?: string
    tag?: string
    twitterCard?: string
    twitterSite?: string
    twitterCreator?: string
    children?: any
}

const Meta = (props: Props) => {
    const {
        title = "",
        description = "",
        ogTitle = "",
        ogImage = "",
        type = "",
        url = "",
        publishedTime = "",
        modifiedTime = "",
        section = "",
        tag = "",
        twitterCard = "",
        twitterSite = "",
        twitterCreator = "",
    } = props

    const imageUrl = ogImage ? imageBuilder.image(ogImage)?.url() : null

    const name = "Turn knowledge into better decisions - ShiftX"
    const titleTag =
        title !== undefined && title.length > 0 ? `${title} – ${name}` : name

    return (
        <Head>
            <title>{title}</title>
            {description !== undefined && description.length > 0 && (
                <meta name="description" content={description} />
            )}
            {title !== undefined && title.length > 0 && (
                <meta itemProp="name" content={title} />
            )}
            {ogTitle !== undefined && ogTitle.length > 0 && (
                <meta property="og:title" content={ogTitle} />
            )}
            {imageUrl && <meta itemProp="image" content={imageUrl} />}
            {twitterCard !== undefined && twitterCard.length > 0 && (
                <meta name="twitter:card" content={twitterCard} />
            )}
            {twitterSite !== undefined && twitterSite.length > 0 && (
                <meta name="twitter:site" content={twitterSite} />
            )}
            {title !== undefined && title.length > 0 && (
                <meta name="twitter:title" content={title} />
            )}
            {description !== undefined && description.length > 0 && (
                <meta name="twitter:description" content={description} />
            )}
            {twitterCreator !== undefined && twitterCreator.length > 0 && (
                <meta name="twitter:creator" content={twitterCreator} />
            )}
            {imageUrl && <meta name="twitter:image" content={imageUrl} />}
            {title !== undefined && title.length > 0 && (
                <meta property="og:title" content={title} />
            )}
            {type !== undefined && type.length > 0 && (
                <meta property="og:type" content={type} />
            )}
            {url !== undefined && url.length > 0 && (
                <meta property="og:url" content={url} />
            )}
            {imageUrl && <meta property="og:image" content={imageUrl} />}
            {description !== undefined && description.length > 0 && (
                <meta property="og:description" content={description} />
            )}
            <meta property="og:site_name" content={name} />
            {publishedTime !== undefined && publishedTime.length > 0 && (
                <meta
                    property="article:published_time"
                    content={publishedTime}
                />
            )}
            {modifiedTime !== undefined && modifiedTime.length > 0 && (
                <meta property="article:modified_time" content={modifiedTime} />
            )}
            {section !== undefined && section.length > 0 && (
                <meta property="article:section" content={section} />
            )}
            {tag !== undefined && tag.length > 0 && (
                <meta property="article:tag" content={tag} />
            )}
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
            />

            <meta name="apple-mobile-web-app-capable" content="yes" />

            {props?.children}
        </Head>
    )
}

export default Meta

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import React, { useState } from "react"
import {
    Input,
    LoadIndicator,
    SearchListItemContainer,
    SearchList,
    usePopover,
} from "../.."
import { MenuItemType, MenuListItem } from "../MenuList/MenuListItem"
import { PopoverContent } from "../Popover/PopoverContent"

interface Props {
    items: any[]
    onSelectItem: ({ item }: { item: any }) => void
    placeholder?: string
    searchKey?: string
    initialSelectedItemId?: string
    isLoading?: boolean
    itemType?: MenuItemType
}

const SelectContainer = styled.div`
    display: flex;
    position: relative;
`

export const SelectSearch = ({
    items,
    onSelectItem,
    placeholder,
    searchKey = "label",
    initialSelectedItemId,
    isLoading = false,
    itemType = "text",
}: Props) => {
    const popover = usePopover()
    const [searchValue, setSearchValue] = useState("")

    return (
        <div>
            <SelectContainer>
                <Input
                    ref={popover.setReference("addInfo")}
                    onChange={event => {
                        setSearchValue(event.target.value)
                        if (popover.show !== "addInfo") {
                            popover.setShow("addInfo")
                        } else if (!event.target.value) {
                            popover.hide()
                        }
                    }}
                    value={searchValue}
                    placeholder={placeholder}
                />

                {isLoading && (
                    <LoadIndicator
                        css={css`
                            position: absolute;
                            right: 12px;
                        `}
                        size={4}
                    />
                )}
            </SelectContainer>

            <PopoverContent
                offset={[0, 4]}
                id="addInfo"
                {...popover}
                placement="bottom-start"
            >
                <SearchList
                    showSearch={false}
                    initialSelectedItemId={initialSelectedItemId}
                    items={items}
                    externalSearchValue={searchValue}
                    onSelectItem={({ item }) => {
                        items
                            .find(current => current.id === item.id)
                            ?.onSelectItem?.({ item })
                        onSelectItem && onSelectItem({ item })
                        setSearchValue("")
                        popover.hide()
                    }}
                    showShadow={true}
                    searchKey={searchKey}
                    itemComp={props => {
                        return (
                            <SearchListItemContainer
                                {...props}
                                showPadding={true}
                            >
                                <MenuListItem type={itemType} {...props} />
                            </SearchListItemContainer>
                        )
                    }}
                />
            </PopoverContent>
        </div>
    )
}

import axios, { CancelToken } from "axios"
import { sha256hash } from "./sha256hash"
import { useReducer, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
export interface GraphQLProps {
    query: string
    variables?: any
    useEdge?: boolean
    usePublicKey?: boolean
    cancelToken?: CancelToken
    customHeaders?: { [key: string]: string }
}

const generateHeaders = async (
    body,
    useEdge,
    usePublicKey,
    customHeaders = {}
) => {
    const headers = {
        "Content-Type": "application/json",
        "Payload-Hash": await sha256hash(body),
        "SX-Edge": useEdge ? "1" : "0",
        "SX-Public": usePublicKey ? "1" : "0",
        "SX-TraceId": uuidv4(),
        ...customHeaders,
    }
    return headers
}

export async function graphqlClient({
    query,
    variables,
    useEdge = false,
    usePublicKey = false,
    cancelToken,
    customHeaders,
}: GraphQLProps) {
    const body = JSON.stringify({ query, variables })
    return axios
        .post("/api/graphql", body, {
            headers: await generateHeaders(
                body,
                useEdge,
                usePublicKey,
                customHeaders
            ),
            withCredentials: true,
            cancelToken,
        })
        .then(res => (res?.data ? res.data : res))
}

export const mockUseGraphQL = (
    reducer,
    { error, latency = 0, data },
    onSuccess
) => {
    const [state, dispatch] = useReducer(reducer, {
        isFetching: true,
        isError: false,
        data: null,
        errors: null,
    })

    const delay = (ms = 500) => {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function runQuery() {
        await delay(latency)
        onSuccess && onSuccess(data)
        dispatch({ isFetching: false, data })
    }
    useEffect(() => {
        runQuery()
    }, [data])
    return state
}

import { css } from "@emotion/react"
import BlockContent from "@sanity/block-content-to-react"
import React from "react"
import serializers from "./Serializers"

export const BodyText = ({ content, ...props }) => {
    return (
        <div
            css={css`
                img {
                    width: 100%;
                }

                figure {
                    margin: 0;
                }

                ul {
                    list-style: none;
                    margin: 24px 0;
                    padding: 0;
                }

                a {
                    color: #4040f0;
                }

                li::before {
                    content: "•";
                    color: #4040f0;
                    display: inline-block;
                    width: 1.5rem;
                    font-size: 20px;
                }

                li {
                    margin-bottom: 16px;
                }

                p:first-child {
                    margin-top: 0;
                }

                img {
                    width: 100%;
                }

                figure {
                    margin: 0;
                }

                h1,
                h2,
                h3 {
                    margin-top: 40px;
                }

                h1 {
                    font-size: 40px;
                    line-height: 56px;
                    color: rgba(0, 0, 0, 0.87);
                    font-weight: bold;
                }

                h2 {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 40px;
                    color: rgba(0, 0, 0, 0.87);
                }

                blockquote {
                    font-style: italic;
                    font-weight: 600;
                    margin: 40px 60px;
                    text-align: center;
                }
            `}
            {...props}
        >
            <BlockContent
                blocks={content}
                serializers={serializers}
                projectId="uq4w4z8u"
                imageOptions={{
                    w: 2000,
                    h: 1000,
                    fit: "max",
                }}
                dataset="production"
            />
        </div>
    )
}

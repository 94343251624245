import styled from "@emotion/styled"

export const Link = styled.a`
    cursor: pointer;
    border-bottom: 1px solid var(--brand-base);
    padding-bottom: 2px;
    position: relative;
    color: var(--brand-base);

    :hover {
        color: var(--brand-base);
    }
`

import styled from "@emotion/styled"
import { getThemeProp, TextStyle } from "@shiftx/styles"
import React from "react"
import {
    IssueStatus,
    IssueStatusIcon,
} from "../../IssueStatusIcon/IssueStatusIcon"

const Container = styled.div<{ hidePadding?: boolean }>`
    align-items: center;
    display: flex;
    ${props => getThemeProp(props.theme, TextStyle.TextSmall)};
    ${props => !props.hidePadding && `padding: 8px 12px;`}
`

const Label = styled.div`
    padding: 0 8px;
`

export interface IssueStatusItemType {
    id: IssueStatus
    label: string
}
interface IssueStatusItemProps {
    item: IssueStatusItemType
    hidePadding?: boolean
}

export const StatusListItem = ({
    item,
    hidePadding = false,
}: IssueStatusItemProps) => {
    if (!item) {
        return null
    }

    return (
        <Container hidePadding={hidePadding}>
            <IssueStatusIcon id={item.id} />
            <Label>{item?.label}</Label>
        </Container>
    )
}

import styled from "@emotion/styled"
import React, { Fragment } from "react"
import { ActorCategories, actorCategories } from "../../../data/actorCategories"
import { ActorAvatar } from "../../ActorAvatar/ActorAvatar"

export const getCategory = (actor): ActorCategories => {
    if (!actor || !actor.category) {
        return {
            id: "",
            name: "",
            color: "",
            colorTheme: " notationColors.turquoise",
        }
    }

    const findCategory = actorCategories.find(ac => ac.id === actor.category)
    if (findCategory) {
        return findCategory
    }
}

const ActorName = styled.div`
    margin-left: 8px;
    flex: 1;
`

export interface ActorItem {
    id: string
    name?: string
    label?: string
    icon?: any
}
export interface ActorListItemProps {
    item: ActorItem
    hidePadding?: boolean
}

export const ActorListItem = ({
    item,
    hidePadding = false,
}: ActorListItemProps) => {
    return (
        <Fragment>
            <ActorAvatar actor={item} />
            <ActorName>{item.name ?? item.label}</ActorName>
        </Fragment>
    )
}
